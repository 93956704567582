import React from "react";

interface Props {
  title: string;
  onClick?(): void;
  icon?: React.ReactNode;
  description?: string;
  testId?: string;
}

export const HomeItem: React.FC<Props> = ({
  title,
  onClick,
  icon,
  description,
  testId,
}) => {
  return (
    <>
      <div
        onClick={onClick}
        className="home-item"
        data-test-id={process.env.REACT_APP_TEST_ID === "show" ? testId : ""}
      >
        <div className="home-item__icon">{icon}</div>
        <div className="home-item__title bold-40">{title}</div>
        <div className="home-item__description">{description}</div>
      </div>
    </>
  );
};
