import classNames from "classnames";
import * as React from "react";
import { SlotResponse } from "src/services/api/OrdersApi";
import { DayLiteral, SelectSlot } from "src/types/slots";
import "./style.scss";
interface Props {
  slots: SlotResponse;
  onSelectSlot?(slot: SelectSlot): void;
  selectedSlot?: SelectSlot | null;
  close?(): void
}

export const DAY_LIST: {
  title: string;
  day: DayLiteral;
}[] = [
  {
    title: "Сегодня",
    day: "today",
  },

  {
    title: "Завтра",
    day: "tomorrow",
  },
  {
    title: "Послезавтра",
    day: "after_tomorrow",
  },
];

export const SlotsModal: React.FC<Props> = ({
  slots,
  onSelectSlot,
  selectedSlot,
  close
}) => {
  const [currentDay, setCurrentDay] = React.useState<DayLiteral>(
    selectedSlot?.day || "today"
  );

  const onDay = (day: DayLiteral) => {
    setCurrentDay(day);
  };

  const slotList = slots[currentDay];
  return (
    <div className="delivery-slots delivery-slots--radius6">
      <div className="delivery-slots--overlay" onClick={close}/>
      <header className="delivery-slots__header">
        <ul>
          {DAY_LIST.map((item) => {
            return (
              <li
                key={item.day}
                onClick={() => onDay(item.day)}
                className={classNames({
                  active: currentDay === item.day,
                  disabled: !slots[item.day]?.length,
                })}
              >
                {item.title}
              </li>
            );
          })}
        </ul>
      </header>

      <ul className="delivery-slots__list">
        {slotList.map((item) => {
          return (
            <li
              key={item.customer_timeslot.slot_number}
              onClick={() =>
                onSelectSlot &&
                onSelectSlot({
                  ...item.customer_timeslot,
                  day: currentDay,
                })
              }
              className={classNames({
                active:
                  selectedSlot &&
                  selectedSlot.slot_number ===
                    item.customer_timeslot.slot_number &&
                  currentDay === (selectedSlot && selectedSlot.day),
              })}
            >
              {item.customer_timeslot.slot_from} -
              {item.customer_timeslot.slot_to}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
