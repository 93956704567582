export const CreateData = {
  FirstColumn: {
    title: "Заказ",
    description: "Адреса магазинов добавляются через профиль. Сумма заказа указывается для выкупа исполнителем вашего заказа на точке продажи (исполнитель использует собственные денежные средства, заказчик оплачивает сумму картой на сайте сервиса), если нужна просто доставка без выкупа, то оставьте «0».",
    inputs: {
      shop: {
        placeholder: "Адрес магазина*",
        description: "Увидит Заказчик и Исполнитель",
      },
      number: {
        placeholder: "Номер заказа в вашей системе*",
        description: "Не увидит Заказчик и Исполнитель",
      },
      amount: {
        placeholder: "Точная сумма заказа*",
        description:
          "Например, 1234.00. До 6000 рублей. Увидит Заказчик и Исполнитель",
      },
      details: {
        placeholder: "Номер заказа или детали получения",
        description: "Увидит Заказчик и Исполнитель",
      },
    },
  },
  SecondColumn: {
    title: "Доставка",
    description: "Получатель не является плательщиком заказа и/или услуги доставки. Это человек, который получит доставку (при этом получатель и заказчик может быть одним лицом). Заказы на доставку с термосумкой могу быть размещены только с предварительного согласования с сервисом. Исполнитель может выполнять только один заказ единовременно, строго, день доставки в день забора.",
    inputs: {
      address: {
        placeholder: "Улица и дом доставки*",
      },
      apartment: {
        placeholder: "кв/офис",
      },
      entrance: {
        placeholder: "подъезд",
      },
      intercom: {
        placeholder: "домофон",
      },
      floor: {
        placeholder: "этаж",
      },
      time: {
        placeholder: "Время доставки*",
        description: "Только сегодня, завтра и послезавтра",
      },
      comment: {
        placeholder: "Комментарий к адресу доставки",
      },
    },
  },
  ThirdColumn: {
    title: "Оплата",
    description: "Заказчик - это лицо, которое оплачивает и ваш заказ и услугу доставки. При совпадении номера телефона заказчика с номером телефона оператора кнопка «Оплата оператором» станет активной (для автоматического заполнения нажмите кнопку «да» ниже). Срок существования ссылки на оплату - 15 минут.",
    inputs: {
      phone: {
        placeholder: "Телефон Заказчика*",
      },
      email: {
        placeholder: "E-mail Заказчика",
      },
      name: {
        placeholder: "Имя Заказчика",
      },
    },
  },
  FourthColumn: {
    inputs: {
      phone: {
        placeholder: "Телефон Получателя*",
      },
      email: {
        placeholder: "E-mail Заказчика",
      },
      name: {
        placeholder: "Имя Получателя",
      },
    },
    button:{
      termobags:{
        title: "Нужна термосумка",
        description: "Размер сумки 45*35*45 см., до 8 кг."
      },
      payment:{
        title: "Оплата оператором",
        description: "Автоматически заполнить поля данными оператора"
      }
    }
  },
  FifthColumn: {
    text: {
      nonempty: {
        title: "Оплата оператором",
        description: "Вы должны быть авторизованы на сайте spotapp.ru как Заказчик, чтобы открыть ссылку на оплату "
      },
    }
  },
};
