import { AxiosApi } from "src/services/api/AxiosApi";
import { Order } from "src/types/orders";
import { Slot } from "src/types/slots";

export interface SlotData {
  delivery_point: {
    latitude: number;
    longitude: number;
  };
  shops: {
    place: {
      latitude: number;
      longitude: number;
    };
    amount: number;
  }[];
}

export interface SlotResponse {
  today: Slot[];
  tomorrow: Slot[];
  after_tomorrow: Slot[];
}

export interface CalculateResponse {
  amount: number;
  amount_cash: number;
  amount_hold: number;
  amount_pay_online: number;
}

type Location = { latitude: number; longitude: number };
export interface CalculateData {
  delivery_date: string;
  delivery_point: Location;
  order_type: string;
  shops: { place: Location; amount: number; parcel_weight: number }[];
  slot: number;
}

export interface OrderCreateData {
  number_in_shop: string;
  shop_address: number;
  amount: number;
  order_details: string;
  deliveryorder: {
    time_from: number;
    delivery_date: string;
    latitude: number;
    longitude: number;
    street: string;
    number: string;
    entrance?: string;
    intercom?: string;
    floor?: string;
    country?: string;
    city?: string;
    apartment?: string;
    contact: {
      name: string;
      phone: string;
    };
  };
  customer_phone: string;
  customer_email?: string;
  customer_name?: string;
  require_termobag: boolean;
}

export interface OrderRecipientCreateData {
  number_in_shop: string;
  shop_address: number;
  amount: number;
  order_details: string;
  deliveryorder: {
    time_from: number;
    delivery_date: string;
    description?: string;
    latitude: number;
    longitude: number;
    street: string;
    number: string;
    entrance?: string;
    intercom?: string;
    floor?: string;
    country?: string;
    city?: string;
    apartment?: string;
    contact: {
      name: string;
      phone: string;
    };
  };
  customer_phone: string;
  customer_email?: string;
  customer_name?: string;
  require_termobag: boolean;
}

export interface OrderParams {
  number_in_shop: string;
  order__customer__phone: string;
  filter_by?: string;
  shop_operator?: string;
  page: number;
  page_size: number;
}

type Result<T> = {
  count: number;
  results: T;
};

export const OrdersApi = {
  getSlots(data: SlotData) {
    return AxiosApi.post<SlotResponse>("/slots/", data);
  },
  calculate(data: CalculateData) {
    return AxiosApi.post<CalculateResponse>("/delivery/calculate_v2/", data);
  },

  shopOrderCreate(data: OrderCreateData) {
    const url = "/shops/shoporders/order_create/";
    return AxiosApi.post(url, data);
  },

  shopRecipientOrderCreate(data: OrderRecipientCreateData) {
    const url = "/shops/shoporders/order_create_with_payment_via_shop/";
    return AxiosApi.post(url, data);
  },

  getOrders(params: OrderParams) {
    return AxiosApi.get<Result<Order[]>>("/shops/shoporders/", { params });
  },
  cancel(id: number) {
    const url = `/shops/shoporders/${id}/cancel/`;
    return AxiosApi.put<Order>(url);
  },
  react(id: number) {
    const url = `/shops/shoporders/${id}/react/`;
    return AxiosApi.put<Order>(url);
  },
};
