import { Address } from "src/types/address";

export function addressFull({
  apartment,
  floor,
  description,
}: Partial<Address>): string {
  let text = "";

  if (apartment) {
    text += `офис ${apartment}, `;
  }
  if (floor) {
    text += `этаж ${floor}, `;
  }

  if (description) {
    text += `${description}`;
  }

  return text;
}
