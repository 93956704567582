import { MKAD_COORDS } from "src/data/mkadCoords";

const mkad = MKAD_COORDS;
const paths: google.maps.LatLng[] = [];
mkad.forEach((coo) => {
  paths.push(new google.maps.LatLng(coo[1], coo[0]));
});
const mkadPolygon = new google.maps.Polygon({ paths });

export const mkadPolygonCheck = (location: google.maps.LatLng) => {
  return google.maps.geometry.poly.containsLocation(location, mkadPolygon);
};
