import * as React from "react";
import { observer } from "mobx-react";

import { useStores } from "src/hooks/useStores";
import { AppButtonCenter } from "../AppButtonCenter";
import { ModalFooter } from "./ModalFooter";
import { ModalHeader } from "./ModalHeader";
import { ReactComponent as Bottom } from "src/assets/img/Arrow_bottom.svg";
import { ReactComponent as Success } from "src/assets/img/Approve.svg";
import { ReactComponent as Attention } from "src/assets/img/Attention.svg";

import "./style.scss";
export const AppModal: React.FC = observer(() => {
  const { modalStore } = useStores();
  const close = () => {
    if (modalStore.button?.onClick) {
      modalStore.button?.onClick();
    }
    modalStore.toggleVisible();
  };

  const getIcon = () => {
    switch (modalStore.icon) {
      case "success":
        return <Success />;

      case "attention":
        return <Attention />;
    }
    return <Success />;
  };
  return (
    <div className="modal-services modal-app">
      <div className="modal-overlay" onClick={() => modalStore.toggleVisible()} />

      <div className="modal-services__container modal-app__container">
        <ModalHeader
          icon={getIcon()}
          title={modalStore.title}
          onClose={() => modalStore.toggleVisible()}
        />
        <div className="modal-app__content">
          {modalStore.messages.map((text, index) => {
            return (
              <div
                key={text.slice(0, 10) + index}
                className="app-modal-message"
              >
                <div className="app-modal-message__text" dangerouslySetInnerHTML={{__html: text}} />
                <div className="app-modal-message__hook">
                  <Bottom />
                </div>
              </div>
            );
          })}
        </div>
        <ModalFooter>
          <div className="modal-app__footer">
            {modalStore.button ? (
              <AppButtonCenter
                title={modalStore.button.title}
                onClick={close}
              />
            ) : null}
          </div>
        </ModalFooter>
      </div>
    </div>
  );
});
