import { AxiosApi } from "./AxiosApi";

export interface GenerateData {
  username: string;
  phone: string;
  role?: string;
  "g-recaptcha-response": string;
}

export interface LoginData {
  phone: string;
  password: string;
  firebase_token: string;
}

export interface LoginResponse {
  access: string;
  refresh: string;
  username: string;
}

export const AuthApi = {
  generatePassword(data: GenerateData) {
    return AxiosApi.post(
      "gen-password/",
      Object.assign(data, {
        user_agent: "",
        user_device: "",
        user_ip: "",
      })
    );
  },
  login(data: LoginData) {
    return AxiosApi.post<LoginResponse>("token/", data);
  },
};
