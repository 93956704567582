export function getOrderStatus(status: string) {
  switch (status) {
    case "created":
      return "Создан оператором";
    case "planned":
      return "Оплачен Заказчиком";
    case "accepted":
    case "in_process":
    case "declined_by_performer":
    case "declined_by_customer":
    case "approved_by_performer":
      return "Исполнитель направился в магазин или уже в нём";

    case "delivered_approval_required":
      return "Исполнитель всё купил, отмена невозможна";

    case "delivered":
      return "Доставлено";
    case "not_paid":
      return "Доставлено";
    case "cancelled":
      return "Отменен";

    case "cancelled_50":
      return "Отменен со штрафом 50%";

    /**
     * Заказ доставлен и закрыт для исполнителя
     */
    case "payment_to_performer_done":
    case "closed":
    case "delivered_not_rated":
    case "payment_partial_refund_done":
      return "Доставлено";
    case "created_detailed": {
      return "Создан оператором";
    }
    default:
      return "Оплачен Заказчиком";
  }
}
