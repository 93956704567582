import * as React from "react";
import "./style.scss";
interface Props {
  title: string;
}

export const AppButtonCenter: React.FC<
  Props & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ title, children, ...props }) => {
  return (
    <button className="app-button-center" {...props}>
      {title}
      {children}
    </button>
  );
};
