import React from "react";
import DeleteIcon from "src/assets/img/Delete@2x.png";
import "./style.scss";
interface Props {
  onRemove?(): void;
  name: string;
  phone: string;
  deletable: boolean;
  role: string;
}

export const ProfileAccount: React.FC<Props> = ({
  onRemove,
  name,
  phone,
  deletable,
  role,
}) => {
  return (
    <div className="profile__list--item">
      <div className="profile__list--item__container">
        <div className="profile-account">
          <div className="profile-account__phone medium-12">{phone}</div>
          <div className="profile-account__name bold-30">{name}</div>

          {deletable && role !== "admin" ? (
            <button className="profile-account__remove" onClick={onRemove}>
              <img src={DeleteIcon} alt="" />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
