import * as React from "react";

import { format } from "date-fns";
import { DAY_LIST } from "src/pages/create/components/SlotsModal/SlotsModal";
import { Order } from "src/types/orders";
import { convertDateToDay } from "src/utils/conertDateToDay";
import OkIcon from "src/assets/img/Ok@2x.png";

import "./style.scss";
import { cutObjectKeyNames } from "src/utils/cutObjectKeyNames";
import { getOrderStatus } from "src/helpers/getOrderStatus";
import { cancelable } from "src/helpers/cancelable";
import { addressFull } from "src/utils/addressFull";

interface Props {
    orders: Order[];
    onCancel(order: Order): void;
    onOk(order: Order): void;
}

const DAY_LIST_SHORT = {
    today: "с",
    tomorrow: "з",
    after_tomorrow: "пз",
};

export const OrderMobileItem: React.FC<Props> = ({ orders, onCancel, onOk }) => {
    return (
        <div className="order--list">
            {orders.map((item) => {
                const operator = item.shop_operator;
                const order = item.order;
                const place = item.order.shopping_addresses?.length
                    ? item.order.shopping_addresses[0]
                    : null;
                const day = convertDateToDay(
                    new Date(order.deliveryorder?.delivery_date)
                );
                const dayText =
                    DAY_LIST.find((item) => item.day === day)?.title || day;
                const customerPhone = item.order?.customer_phone;
                return (
                    <div className={`order--list--item ${item.require_attention ? "active" : ""}`}>
                        <div className="order--list--item__titles">
                            <span>{getOrderStatus(order.status)}</span>
                            <span>{customerPhone}</span>
                        </div>
                        <div className="order--list--item__info">
                            <span>
                                {item.number_in_shop}{" "}
                                {format(new Date(item.created_at), `HH:mm`)}{" "}
                                {format(new Date(item.created_at), `dd-MM-yyy`)}
                            </span>
                            <span>{operator?.last_name} {operator?.first_name}{" "}{operator?.middle_name}</span>
                            <div>
                                <span>
                                    №{order?.id}{" "}
                                    {order?.amounts_data?.service_amount.toFixed(2)}{" "}
                                    <span className="rouble">₽</span>
                                </span>
                            </div>
                            <div
                                onClick={() => onCancel(item)}
                                className="order--list__cancel"
                            >
                                <span className={cancelable(item.order.status) ? "order--list__cancel--active" : ""}>Отменить</span>
                            </div>

                            <hr />

                            <div className="order--list__address">
                                <div className="order--list__address--shop">
                                    {place?.street} | {place?.amount.toFixed(2)} ₽
                                    <span>
                                        {addressFull({
                                            apartment: place?.apartment,
                                            floor: place?.floor,
                                            description: place?.description,
                                        })}
                                    </span>
                                </div>
                                <div className="order--list__address--delivery">
                                    {order?.deliveryorder?.delivery_address_street},{" "}
                                    {order?.deliveryorder?.delivery_address_number}
                                    <span>
                                        {addressFull({
                                            apartment: order?.deliveryorder?.delivery_address_apartment,
                                            floor: order?.deliveryorder?.delivery_address_floor,
                                            description: order?.deliveryorder?.delivery_address_description,
                                        })}
                                    </span>
                                </div>
                                <div className="order--list__address--time">
                                    ≈ {order?.performer_at_first_point_from} -{" "}
                                    {order?.performer_at_first_point_to} {dayText}
                                    <span className="regular-12 order--list__description">
                                        {order?.deliveryorder?.time_from_h} -{" "}
                                        {order?.deliveryorder?.time_to_h}
                                    </span>
                                </div>
                            </div>
                            <div className="order--list__cancel--accept">
                                {item.require_attention ? (
                                    <div
                                        onClick={() => onOk(item)}
                                        className="order--table__attention"
                                    >
                                        <img alt="" src={OkIcon} />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};
