export function cutObjectKeyNames(obj: any, cut: string): any {
  let newObj: {
    [x: string]: any;
  } = {};

  Object.keys(obj).forEach((key) => {
    let newKey = key.replace(cut, "");

    newObj[newKey] = obj[key as string];
  });
  return newObj;
}
