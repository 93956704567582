import React from "react";
import { AppButtonCenter } from "src/components/AppButtonCenter";
import { TextField } from "src/components/TextField";
import { TextFieldPlaces } from "src/components/TextField/TextFieldPlaces";
import { mkadPolygonCheck } from "src/helpers/mkadPolygonCheck";
import { Address, PlaceAddress } from "src/types/address";

interface Props {
  disabled: boolean;
  create(address: Address): void;
}

export const ProfileAddressForm = React.forwardRef<HTMLDivElement, Props>(
  ({ disabled, create }, ref) => {
    const [address, setAddress] = React.useState<PlaceAddress | null>(null);

    const [addressError, setAddressError] = React.useState("");

    const [name, setName] = React.useState("");
    const [apartment, setApartment] = React.useState("");
    const [floor, setFloor] = React.useState("");
    const [description, setDescription] = React.useState("");

    const onAddressSelect = (address: PlaceAddress) => {
      const location = new google.maps.LatLng(
        Number(address.latitude),
        Number(address.longitude)
      );

      setAddressError(mkadPolygonCheck(location) ? "" : "Тут пока не работаем");
      setAddress(address);
    };
    const handleAddressError = () => {
      const error = !address?.street ? "Введите улицу" : "";
      setAddressError(error);
      return error;
    };
    const onCreateAddress = () => {
      const error = handleAddressError();
      if (error || !address) {
        return;
      }

      create({
        ...address,
        floor,
        apartment,
        place_name: name,
        description,
      });
    };

    const buttonDisabled = React.useMemo(() => {
      return !!addressError || disabled;
    }, [addressError, disabled]);
    return (
      <div ref={ref} className="profile__form">
        <div className="profile__form--title bold-30">Добавить адрес</div>

        <TextField
          value={name}
          onChangeText={setName}
          placeholder="Название точки"
          testId="placename_inp"
        />
        <div className="mb-10">
          <TextFieldPlaces
            onSelect={onAddressSelect}
            autoComplete="off"
            placeholder="Улица и дом"
            error={addressError}
            testId="address_inp"
          />
        </div>
        <div className="profile__form--row">
          <div className="profile__form--row__item">
            <TextField
              value={apartment}
              onChangeText={setApartment}
              placeholder="Офис"
              testId="apartment_inp"
            />
          </div>
          <div className="profile__form--row__item">
            <TextField
              value={floor}
              onChangeText={setFloor}
              placeholder="Этаж"
              testId="floor_inp"
            />
          </div>
        </div>
        <TextField
          value={description}
          onChangeText={setDescription}
          placeholder="Комментарий"
          testId="description_inp"
        />

        <div
          className="profile__form--button"
          data-test-id={
            process.env.REACT_APP_TEST_ID === "show" ? "add_address_btn" : ""
          }
        >
          <AppButtonCenter
            disabled={buttonDisabled}
            onClick={onCreateAddress}
            title="Добавить"
          />
        </div>
      </div>
    );
  }
);
