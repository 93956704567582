import * as React from "react";
import { Autocomplete } from "./TextField";

interface Props {
  onClick?(): void;
}

export const TextFieldAutocomplete: React.FC<Props & Autocomplete> = ({
  top,
  bottom,
  onClick,
}) => {
  return (
    <div onClick={onClick} className="text-field__container--item">
      <div className="text-field__container--item--top">{top}</div>
      <div className="text-field__container--item--bottom">{bottom}</div>
    </div>
  );
};
