import React from "react";
import { observer } from "mobx-react";

import "./style.scss";
import { useStores } from "src/hooks/useStores";
import { StorageService } from "src/services/storage";

import { AuthMobileHeader } from "src/pages/auth/components/AuthMobileHeader";
import { ReactComponent as SpotOnIcon } from "../../assets/img/SpotOn_Icon.svg";
import { useHistory, useLocation } from "react-router";
import { RouterNames } from "src/routerNames";

import { AppButtonCenter } from "../AppButtonCenter";

export const Header: React.FC = observer(() => {
  const {
    userStore: { user, removeUser },
  } = useStores();
  const history = useHistory();
  const location = useLocation();

  const goToHome = () => {
    if (history.location.pathname !== RouterNames.Home) {
      history.push(RouterNames.Home);
    }
  };
  const logOut = () => {
    StorageService.removeAccess();
    StorageService.removeLastUpdateFirebaseToken();
    removeUser();
    history.push(RouterNames.Auth);
  };

  return (
    <>
      {[RouterNames.Create, RouterNames.Orders].includes(location.pathname) ? (
        <div className="header">
          <div className="header__right">
            <AppButtonCenter onClick={goToHome} title={"Главная"} />

            <AppButtonCenter onClick={logOut} title={"Выйти"} />
          </div>
        </div>
      ) : (
        <div className="header mb-35">
          <div onClick={goToHome} className="header__icon">
            <SpotOnIcon width={108} height={108} />
          </div>

          <div className="header__content">
            <div className="header__content--title bold-40 text-shadow-0-0-3">
              {user?.shop_name}
            </div>
            <div className="header__content--name bold-30 text-shadow-0-0-3">
              {user?.last_name} {user?.first_name} {user?.middle_name}
            </div>
            <div className="header__content--phone medium-16">
              {user?.phone}
            </div>
          </div>

          <div className="header__right">
            <AppButtonCenter onClick={goToHome} title={"Главная"} />

            <AppButtonCenter onClick={logOut} title={"Выйти"} />
          </div>
        </div>
      )}
      <AuthMobileHeader />
    </>
  );
});
