import { makeAutoObservable } from "mobx";

type ModalIcon = "attention" | "success" | "";
type ModalButton = {
  title: string;
  onClick?(): void;
};

type ModalData = {
  messages: string[];
  title: string;
  icon: ModalIcon;
  button: ModalButton;
};

export class ModalStore {
  visible: boolean = false;
  messages: string[] = [];
  title: string = "";
  icon: ModalIcon = "";
  button: ModalButton | null = null;
  constructor() {
    makeAutoObservable(this);
  }
  toggleVisible() {
    this.visible = !this.visible;
    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } 
    else {
        document.body.style.overflow = "scroll";
    }
  }
  openModal({ messages, title, icon, button }: ModalData) {
    this.title = title;
    this.messages = messages;
    this.icon = icon;
    this.button = button;
    this.toggleVisible();
  }
}
