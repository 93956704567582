import { observer } from "mobx-react";
import React from "react";
import { useStores } from "src/hooks/useStores";
import { CreateOperatorData, ShopsApi } from "src/services/api/ShopsApi";
import { Address } from "src/types/address";
import { addressFull } from "src/utils/addressFull";
import { ProfileAccount } from "./components/ProfileAccount";
import { ProfileAccountForm } from "./components/ProfileAccountForm";
import { ProfileAdd } from "./components/ProfileAdd";
import { ProfileAddress } from "./components/ProfileAddress";
import { ProfileAddressForm } from "./components/ProfileAddressForm";
import "./style.scss";

export const Profile: React.FC = observer(() => {
  const { userStore } = useStores();
  const addressFormRef = React.useRef<HTMLDivElement>(null);
  const accountFormRef = React.useRef<HTMLDivElement>(null);

  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  const [disabled, setDisabled] = React.useState(false);

  const [profileFormVisible, setProfileFormVisible] = React.useState(false);
  const [addressFormVisible, setAddressFormVisible] = React.useState(false);
  const clearTimeoutId = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  };
  const toggleProfileForm = () => {
    setProfileFormVisible((prev) => !prev);
    clearTimeoutId();

    if (!profileFormVisible) {
      timeout.current = setTimeout(
        () =>
          accountFormRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center"
          }),
        200
      );
    }
  };
  const toggleAddressForm = () => {
    setAddressFormVisible((prev) => !prev);
    clearTimeoutId();
    if (!addressFormVisible) {
      timeout.current = setTimeout(
        () =>
          addressFormRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center"
          }),
        200
      );
    }
  };

  const addOperator = (data: CreateOperatorData) => {
    setDisabled(true);

    userStore
      .createOperator(data)
      .then(() => {
        toggleProfileForm();
      })
      .finally(() => {
        setDisabled(false);
      });
  };
  const createAddress = (address: Address) => {
    setDisabled(true);
    userStore
      .createAddress(address)
      .then(() => {
        toggleAddressForm();
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  const deleteAddress = (id: number) => {
    ShopsApi.deleteAddress(id).then(() => {
      userStore.getAddresses();
    });
  };
  const deleteOperator = (id: number) => {
    ShopsApi.deleteOperator(id).then(() => {
      userStore.getOperators();
    });
  };

  React.useEffect(() => {
    userStore.getUserInfo();
  }, [userStore]);

  return (
    <div className="profile page">
      <div className="bold-60 mb-30 profile--title">Пользователи</div>
      <div className="profile__list">
        {userStore.operators.map((item) => {
          return (
            <ProfileAccount
              deletable={userStore.user?.role === "admin"}
              role={item.role}
              key={item.id}
              name={item.first_name}
              phone={item.phone}
              onRemove={() => deleteOperator(item.id)}
            />
          );
        })}
        {userStore.user?.role === 'admin' && (
          <ProfileAdd
            visible={profileFormVisible}
            onClick={toggleProfileForm}
            title="Добавить сотрудника"
            testId="add_operator"
          />
        )}
      </div>
      {profileFormVisible && (
        <ProfileAccountForm ref={accountFormRef} onSave={addOperator} />
      )}
      <div className="bold-60 mb-30 profile--title">Адреса</div>

      <div className="profile__list">
        {userStore.addresses.map((item) => {
          return (
            <ProfileAddress
              key={item.id}
              role={userStore.user?.role}
              text={{
                top: item.place_name,
                center: `${item.street}, ${item.number}`,
                bottom: addressFull(item),
              }}
              onRemove={() => item.id && deleteAddress(item.id)}
            />
          );
        })}
        {userStore.user?.role === 'admin' && (
          <ProfileAdd
            visible={addressFormVisible}
            onClick={toggleAddressForm}
            title="Добавить адрес"
            testId="add_address"
          />
        )}
      </div>
      {addressFormVisible && (
        <ProfileAddressForm
          ref={addressFormRef}
          disabled={disabled}
          create={createAddress}
        />
      )}
    </div>
  );
});
