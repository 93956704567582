interface LinkList {
  main: string;
  qa: string;
  performers: string;
  terms: string;
  contacts: string;
}

export let LINKS: LinkList = {
  main: "http://spotapp.ru",
  qa: "/qa",
  performers: "/for-performers",
  terms: "/terms-of-use",
  contacts: "/contacts",
};
