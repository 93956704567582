import React from "react";
import { AppButtonCenter } from "src/components/AppButtonCenter";
import { TextField } from "src/components/TextField";
import { AxiosApi } from "src/services/api/AxiosApi";
import { CreateOperatorData } from "src/services/api/ShopsApi";
import { clearPhone } from "src/utils/clearPhone";
import { validateEmail } from "src/validation/validateEmail";
import { validateRequired } from "src/validation/validateRequired";

interface Props {
  onSave(operator: CreateOperatorData): void;
  disabled?: boolean;
}

const PHONE_LENGTH = 11;
const RUSSIAN_CODE = "7";
export const ProfileAccountForm = React.forwardRef<HTMLDivElement, Props>(
  ({ onSave, disabled }, ref) => {
    const [errors, setErrors] = React.useState({
      lastName: "",
      name: "",
      email: "",
      phone: "",
    });
    const [lastName, setLastName] = React.useState("");
    const [name, setName] = React.useState("");
    const [middleName, setMiddleName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [emailExists, setEmailExists] = React.useState(true);
    const timeout = React.useRef<ReturnType<typeof setTimeout> | null>(null);
    const onAdd = () => {
      const nameError = onBlurRequired("name", name);
      const lastNameError = onBlurRequired("lastName", lastName);
      const emailError = onBlurEmail();
      const phoneError = onBlurPhone();

      if (nameError || phoneError || lastNameError) {
        return;
      }
      onSave({
        last_name: lastName,
        first_name: name,
        middle_name: middleName,
        email,
        phone: `+${clearPhone(phone)}`,
        role: "operator",
      });
    };
    const onBlurEmail = () => {
      let error = onBlurRequired("email", email);

      error = validateEmail(email) ? "" : "Ввведите корректный e-mail";

      handleErrors({
        email: error,
      });
      return error;
    };
    
    const onChangePhone = (value: string) => {
      const length = value.length;
      const firstLetter = length ? value[1] : "";
      if (firstLetter !== RUSSIAN_CODE && firstLetter !== " ") {
        const slicedPhone = value.slice(2);
        let phone = RUSSIAN_CODE + slicedPhone;
        setPhone(phone);
      }
      else{
        setPhone(value);
      }
    };

    const onBlurPhone = () => {
      const clearlyPhone = clearPhone(phone);
      let error = onBlurRequired("phone", phone);
      error =
        clearlyPhone.length !== PHONE_LENGTH ? "Введите телефон полностью" : "";

      handleErrors({
        phone: error,
      });
      return error;
    };

    const checkEmailExists = React.useCallback(() => {
      timeout.current && clearTimeout(timeout.current);
      if (email) {
        if (validateEmail(email)) {
          timeout.current = setTimeout(() => {
            return AxiosApi.get(`accounts/accounts/check_email/?email=${email}`)
              .then(({ data }) => {
                setEmailExists(data.exists);
              })
              .finally(() => {});
          }, 300);
        }
      } else {
        setEmailExists(false);
      }
    }, [email]);

    const onBlurRequired = (name: keyof typeof errors, value: any) => {
      const error = validateRequired(!value);
      handleErrors({
        [name]: error,
      });

      return error;
    };
    const handleErrors = (errorList: Partial<typeof errors>) => {
      setErrors((prev) => ({
        ...prev,
        ...errorList,
      }));
    };
    const buttonDisabled = React.useMemo(() => {
      let hasError = false;
      for (const key in errors) {
        if (errors[key as keyof typeof errors]) {
          hasError = true;
          break;
        }
      }
      return disabled || hasError || emailExists;
    }, [disabled, emailExists, errors]);

    React.useEffect(() => {
      checkEmailExists();
    }, [checkEmailExists]);
    return (
      <div ref={ref} className="profile__form">
        <div className="profile__form--title bold-30">Добавить сотрудника</div>
        <TextField
          value={lastName}
          onChangeText={setLastName}
          placeholder="Фамилия"
          error={errors.lastName}
          onBlur={() => {
            onBlurRequired("lastName", lastName);
          }}
          testId="lastname_inp"
        />
        <TextField
          value={name}
          onChangeText={setName}
          placeholder="Имя"
          error={errors.name}
          onBlur={() => {
            onBlurRequired("name", name);
          }}
          testId="firstname_inp"
        />
        <TextField
          value={middleName}
          onChangeText={setMiddleName}
          placeholder="Отчество"
          testId="middlename_inp"
        />
        <TextField
          value={phone}
          onChangeText={onChangePhone}
          mask="+# (###) ###-##-##"
          placeholder="Телефон"
          type="tel"
          error={errors.phone}
          onBlur={() => onBlurPhone()}
          testId="phone_inp"
        />
        {/* <TextField
          value={email}
          onChangeText={setEmail}
          placeholder="E-mail"
          error={errors.email || emailExists ? "E-mail уже существует" : ""}
          onBlur={() => onBlurEmail()}
          testId="email_inp"
        /> */}
        <div
          className="profile__form--button"
          data-test-id={
            process.env.REACT_APP_TEST_ID === "show" ? "add_operator_btn" : ""
          }
        >
          <AppButtonCenter
            disabled={buttonDisabled}
            onClick={onAdd}
            title="Добавить"
          />
        </div>
      </div>
    );
  }
);
