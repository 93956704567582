import React from "react";
import debounce from "lodash.debounce";

import "./style.scss";
import { StatusTab } from "./components/StatusTab";
import { TabItem } from "./components/StatusTab/StatusTab";
import { TextField } from "../../components/TextField";
import { OrdersTable } from "./components/OrdersTable";
import { OrderParams, OrdersApi } from "src/services/api/OrdersApi";
import { Order } from "src/types/orders";
import { useStores } from "src/hooks/useStores";
import { Autocomplete } from "src/components/TextField/TextField";
import { cancelable } from "src/helpers/cancelable";
import { Pagination } from "./components/Pagination";
import { useHistory, useLocation } from "react-router";
import { OrderMobileItem } from "./components/OrdersTable/OrderMobileItem";

const STATUS_LIST: TabItem[] = [
  {
    text: "в работе",
    value: "work",
  },
  {
    text: "отмененные",
    value: "cancelled",
  },
  {
    text: "закрытые",
    value: "closed",
  },
  {
    text: "сегодня",
    value: "today",
  },
  {
    text: "оплачен заказчиком",
    value: "payed",
  },
];

function useQuery() {
  return new URLSearchParams(useLocation().pathname);
}
const description = `Внесение изменений в существующие заказы невозможно. Необходимо создать
новый и попросить Заказчика не оплачивать текущий, если заказ в статусе
«Создан». Отмена заказа здесь возможна только после оплаты Заказчиком и
до назначения Исполнителя. Возможность отмены в остальных случаях
уточняйте у оператора службы поддержки. Заказчик может сам отменить
заказ после оплаты до назначения Исполнителя. Исполнитель будет
отображен только у Заказчика в личном кабинете.`;

const DEFAULT_LIMIT = 20;
export const Orders: React.FC = () => {
  const query: any = useQuery();

  const history = useHistory();
  const { userStore, modalStore } = useStores();
  const [count, setCount] = React.useState(0);
  const operators = userStore.operators;
  const [status, setStatus] = React.useState<string[]>([]);
  const [statusAnimation, setStatusAnimation] = React.useState(false);
  const [orders, setOrders] = React.useState<Order[]>([]);
  const [phone, setPhone] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [operator, setOperator] = React.useState("");
  const [operatorId, setOperatorId] = React.useState<any>("");
  const [page, setPage] = React.useState(query.get("page") || 1);
  const timeout = React.useRef<any>(null);
  
  const debouncedGetOrders = React.useRef(
    debounce((params?: Partial<OrderParams>) => {
      return OrdersApi.getOrders({
        number_in_shop: number,
        order__customer__phone: phone,
        filter_by: status.join(","),
        shop_operator: operatorId,
        page,
        page_size: DEFAULT_LIMIT,
        ...params,
      }).then(({ data }) => {
        setOrders(data.results);
        setCount(Math.ceil(data.count / DEFAULT_LIMIT));
      });
    }, 300)
  );
  const onCancel = (item: Order) => {
    if (cancelable(item.order.status)) {
      modalStore.openModal({
        title: "Отмена заказа",
        messages: [
          "📨 Вы уверены, что хотите отменить заказ? Будьте внимательны: заказчику будет отправлен e-mail об отмене, а получатель каких-либо уведомлений не получит.",
        ],
        icon: "success",
        button: {
          title: "Да",
          onClick: () => OrdersApi.cancel(item.id).then(() => getOrders()),
        },
      });
    }
  };
  const onOk = (item: Order) => {
    OrdersApi.react(item.id).then(() => getOrders());
  };
  const getOrders = React.useCallback(
    (params: Partial<OrderParams> = {}) => debouncedGetOrders.current(params),
    []
  );

  const onChangeNumber = (text: string) => {
    setNumber(text);
    getOrders({
      number_in_shop: text,
    });
  };
  const onChangeStatus = (value: string) => {
    let statusList: string[] = [];
    if (status.includes(value)) {
      statusList = status.filter((item) => item !== value);
    } else {
      statusList = [...status, value];
    }

    setStatus(statusList);
    setStatusAnimation(true);
  };

  const onChangeOperatorId = (id: any) => {
    setOperatorId(id);
    if (id) {
      getOrders({
        shop_operator: id,
      });
    }
  };
  const onChangeOperator = (text: string) => {
    setOperator(text);
    if (text.length === 0) {
      setOperatorId("");
    }
  };
  const onChangePhone = (text: string) => {
    setPhone(text);
    if (phone.length === 11) {
      getOrders({
        order__customer__phone: text,
      });
    }
  };
  const changePage = (page: number) => {
    setPage(page);
    history.push(`/orders?page=${page}`);
  };

  React.useEffect(() => {
    if (!statusAnimation){
      timeout.current = setTimeout(() => setStatusAnimation(true));
      document.body.style.overflow = "hidden";
      timeout.current = setTimeout(() => document.body.style.overflow = "scroll", 3000)
    }
    if (status.length === 0) {
      setStatusAnimation(false);
    }
    if (status.length > 0) {
      timeout.current = setTimeout(() => getOrders({filter_by: `${status}`}), 1500);
    }
    timeout.current = setTimeout(() => setStatusAnimation(false), 3000);
  },[status]);

  React.useEffect(() => {
    getOrders();
    if (phone.length === 0) {
      getOrders();
    }
  }, [page, phone]);

  const operatorList: Autocomplete[] = operators.map((operator) => ({
    top: `${operator?.last_name} ${operator?.first_name} ${operator?.middle_name}`,
    bottom: "",
    id: operator.id,
  }));

  return (
    <div className="order page">
      <div className={`order--status ${statusAnimation ? "active" : ""}`}></div>
      <h1>Заказы</h1>
      <StatusTab list={STATUS_LIST} onChange={onChangeStatus} active={status} />
      <div className="order--description bold-14">{description}</div>
      <div className="order--search__text bold-20 text-shadow-0-0-3">Поиск</div>

      <div className="order--search__container">
        <div className="order--search__item">
          <TextField
            value={phone}
            onChangeText={onChangePhone}
            placeholder="Телефон"
          />
        </div>
        <div className="order--search__item">
          <TextField
            value={number}
            onChangeText={onChangeNumber}
            placeholder="Ваш номер заказа"
          />
        </div>
        <div className="order--search__item">
          <TextField
            autocompleteList={operatorList}
            value={operator}
            onChangeText={onChangeOperator}
            onSelectItem={onChangeOperatorId}
            placeholder="Оператор"
          />
        </div>
      </div>
      <OrdersTable onOk={onOk} onCancel={onCancel} orders={orders} />
      <OrderMobileItem onOk={onOk} onCancel={onCancel} orders={orders} />
      <Pagination count={count} active={page} changePage={changePage} />
    </div>
  );
};

