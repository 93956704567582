export function convertDateToDay(date: Date) {
  const now = new Date(Date.now());
  const difference = date.getDate() - now.getDate();

  if (difference === 2) {
    return "after_tomorrow";
  }
  if (difference === 1) {
    return "tomorrow";
  }
  if (difference < 0) {
    return date.toLocaleDateString();
  }

  return "today";
}
