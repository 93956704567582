import { Address } from "src/types/address";
import { Operator } from "src/types/shops";
import { AxiosApi } from "./AxiosApi";

export type CreateOperatorData = Omit<Operator, "shop" | "id">;

export interface Thermobag {
  require_termobag_default: boolean;
}

export const ShopsApi = {
  createOperator(data: CreateOperatorData) {
    const url = `shops/shopoperators/operator_create/`;
    return AxiosApi.post<Operator>(url, data);
  },
  deleteOperator(id: number) {
    const url = `shops/shopoperators/${id}/`;

    return AxiosApi.delete(url);
  },
  getOperators() {
    const url = `shops/shopoperators/`;
    return AxiosApi.get<Operator[]>(url);
  },

  deleteAddress(id: number) {
    const url = `shops/shopaddresses/${id}/`;
    return AxiosApi.delete(url);
  },
  createAddress(data: Address) {
    const url = `shops/shopaddresses/`;
    return AxiosApi.post(url, data);
  },
  getAddresses() {
    const url = `shops/shopaddresses/`;
    return AxiosApi.get<Address[]>(url);
  },
  getMe() {
    const url = `shops/shopoperators/me/`;

    return AxiosApi.get<Operator>(url);
  },
};
