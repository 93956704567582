export const MKAD_COORDS = [
  [37.36950823959306, 55.745932707664679],
  [37.37395946202632, 55.736654897608673],
  [37.387900536906791, 55.710775870438773],
  [37.410844831795401, 55.693386655409341],
  [37.417410619896238, 55.682840659841368],
  [37.427467786531103, 55.667706831516213],
  [37.381466624068253, 55.65608753795955],
  [37.36973855236797, 55.655736071564299],
  [37.361678351288987, 55.648209512794082],
  [37.346558548136478, 55.64647063488642],
  [37.343596064029967, 55.64802347224785],
  [37.34578752586269, 55.654582797780101],
  [37.340980511374731, 55.654150817801828],
  [37.334930090074913, 55.651269966608872],
  [37.331685735701477, 55.647586514275112],
  [37.327557925673453, 55.646297585475608],
  [37.305606518865943, 55.646184931216681],
  [37.30600466291493, 55.641487685695111],
  [37.300681405795267, 55.637431791764463],
  [37.302360075537813, 55.629547224668883],
  [37.307121686010973, 55.62694189661449],
  [37.297649403315461, 55.625485517567633],
  [37.299819998885098, 55.622496031300273],
  [37.306057231300223, 55.619211137650034],
  [37.312984394535263, 55.622564969511266],
  [37.328371518793908, 55.624858686875037],
  [37.337487572852773, 55.626917706996743],
  [37.339074160238702, 55.621201997669893],
  [37.346686423668118, 55.6239756498343],
  [37.348051764150973, 55.625988368860639],
  [37.346009604639619, 55.629198932210393],
  [37.340774727676653, 55.630416272357529],
  [37.350601136413253, 55.636344920293993],
  [37.360521638443089, 55.63179816824168],
  [37.365180470113117, 55.630999841191773],
  [37.374637363019232, 55.635851567848107],
  [37.372813212967372, 55.640188454165497],
  [37.380043849696591, 55.639823909598931],
  [37.386288028048071, 55.635688868441058],
  [37.396711364170763, 55.637400888189163],
  [37.41243317655757, 55.635310519224177],
  [37.416744126385098, 55.638859788183467],
  [37.417133987783551, 55.644217547791058],
  [37.424286623802338, 55.650714923988339],
  [37.428779278746909, 55.647811747247118],
  [37.43622479207292, 55.646746020644379],
  [37.446856054578767, 55.649648975522858],
  [37.459541180515778, 55.638951503904273],
  [37.451551707283123, 55.635057404603529],
  [37.416982098373182, 55.625437115592923],
  [37.405342489129502, 55.620243879444537],
  [37.406090443409369, 55.614493463529499],
  [37.411020932773603, 55.614645957428962],
  [37.401571827380103, 55.603494247463402],
  [37.421477350419877, 55.591077330113919],
  [37.432152402267732, 55.585776107876548],
  [37.444565194059663, 55.581560853928842],
  [37.449453955213592, 55.576519764447703],
  [37.43924448896076, 55.568535577187937],
  [37.443724776422407, 55.566009012614998],
  [37.450740081872453, 55.565518891568438],
  [37.458475987991683, 55.559883670286197],
  [37.458171702649388, 55.557847443203293],
  [37.459581282946537, 55.555321031712538],
  [37.465762153292147, 55.551156882466913],
  [37.486446380135369, 55.562058057808201],
  [37.495272235471823, 55.564028638231967],
  [37.496328240175238, 55.56168961590533],
  [37.500151279783672, 55.558325645847141],
  [37.507158404388143, 55.560982812615237],
  [37.495609925038607, 55.571713822884988],
  [37.483895098314733, 55.577662876987787],
  [37.48325227570696, 55.578778927629102],
  [37.484592033866193, 55.580225222391142],
  [37.482359294517309, 55.582061760412827],
  [37.480781297300346, 55.591406787826223],
  [37.477448031280112, 55.592666805898013],
  [37.48508852898469, 55.603790728088583],
  [37.492520671348693, 55.600773802107433],
  [37.501886755397443, 55.59508755970618],
  [37.513551308569788, 55.595110692359647],
  [37.538671116159762, 55.589231079220333],
  [37.570860225081532, 55.581198948227289],
  [37.55458866809839, 55.571298322501249],
  [37.550209506272147, 55.562783727580872],
  [37.554443564137678, 55.559987532387133],
  [37.553444620279272, 55.555974202327953],
  [37.550473285572508, 55.552622478681833],
  [37.548100759933128, 55.551895329791073],
  [37.536145691759224, 55.55394407935534],
  [37.525342594979463, 55.555981915010108],
  [37.516428494560408, 55.552079300285158],
  [37.52240309307755, 55.54892846634214],
  [37.514422190749663, 55.546398864464919],
  [37.508262187403588, 55.542983318412148],
  [37.499314018763073, 55.541086773056627],
  [37.498038852671812, 55.544569660094758],
  [37.488994948304523, 55.543628696872929],
  [37.486648296215073, 55.547672381752143],
  [37.479649673070732, 55.549070015720673],
  [37.473410157360348, 55.545843713850481],
  [37.481004273274763, 55.539897667055797],
  [37.496493872975798, 55.540271405477888],
  [37.496418780378967, 55.53150753345065],
  [37.507082890262389, 55.529296182559989],
  [37.506790047247129, 55.522313356471017],
  [37.520034171683001, 55.522722901701982],
  [37.526779437716968, 55.52143793296495],
  [37.523547186779183, 55.526097558672362],
  [37.519154875715387, 55.528895327134627],
  [37.532936782893131, 55.53205325132555],
  [37.545526141401773, 55.538192865954237],
  [37.547033997277353, 55.541981719594837],
  [37.556554059642998, 55.539109753643821],
  [37.557554079137731, 55.540926246007409],
  [37.569847190441578, 55.535904883660393],
  [37.574501158567038, 55.547772851994907],
  [37.603416950650669, 55.564426069380502],
  [37.600085526085103, 55.567875623818978],
  [37.599501819612719, 55.571493366352392],
  [37.607133291979423, 55.575557379113661],
  [37.612632526991057, 55.575157119674863],
  [37.644754476996908, 55.573031418847137],
  [37.668290968382323, 55.571832714081168],
  [37.679554422964557, 55.573261215950517],
  [37.683800469144167, 55.574670368564199],
  [37.725508313550748, 55.590203079760208],
  [37.754473456055408, 55.602657755876983],
  [37.758582554278277, 55.604932612355057],
  [37.787559538595502, 55.620569439520303],
  [37.810578449316033, 55.634647516918072],
  [37.834847900547871, 55.650914108154858],
  [37.837573403608232, 55.652149349861723],
  [37.848176364221317, 55.651624590511183],
  [37.856996992073363, 55.652670693779712],
  [37.862252638768823, 55.653924064133079],
  [37.868668048841727, 55.657923551217117],
  [37.887519647536443, 55.66951786818332],
  [37.902711566612851, 55.674415574028053],
  [37.901504749683149, 55.675885187224168],
  [37.908343116881852, 55.679765530072878],
  [37.915889328316098, 55.676504224249243],
  [37.947600603657513, 55.67530633254821],
  [37.958347967724421, 55.6739013452422],
  [37.962742276332953, 55.672410232809973],
  [37.963855315498392, 55.674301956555581],
  [37.96156569198866, 55.675593106684488],
  [37.963755336338878, 55.682209314998531],
  [37.964121223423149, 55.683386631363227],
  [37.962215043194853, 55.685057514764999],
  [37.964348004945869, 55.685731652279451],
  [37.964445547658578, 55.687258246813073],
  [37.961705729306964, 55.688021410686737],
  [37.960322656057727, 55.689264300413292],
  [37.958246806723693, 55.689303653337937],
  [37.960013835100789, 55.694723857423078],
  [37.967627537996243, 55.701731381515067],
  [37.963973852462132, 55.709061256433138],
  [37.966795824812252, 55.710431280830292],
  [37.959273540888333, 55.714428634214428],
  [37.966766773146801, 55.716357383422597],
  [37.958657233306347, 55.717485234042321],
  [37.956159204734753, 55.719980637716588],
  [37.946172349530379, 55.720828228916787],
  [37.937575306563943, 55.726743826955207],
  [37.930563520427732, 55.729802379621631],
  [37.922823694120382, 55.731841130157093],
  [37.915879895243947, 55.728291147890893],
  [37.89029056169371, 55.723316198718251],
  [37.890634790626208, 55.724195171845317],
  [37.888133359045653, 55.724825952987118],
  [37.886599623912318, 55.718991182840902],
  [37.880303034312441, 55.719336445554973],
  [37.874771378946477, 55.720606535810447],
  [37.870279154718148, 55.72625246438399],
  [37.8723270462865, 55.728451920691633],
  [37.872107394705907, 55.73082835904443],
  [37.868500812935572, 55.732980969114031],
  [37.867462848548961, 55.73721632463927],
  [37.875322786597977, 55.739928295333911],
  [37.88302727853209, 55.736256624827512],
  [37.890394867338053, 55.742096659000161],
  [37.888320998727472, 55.748222364168669],
  [37.88313994077852, 55.749587022802316],
  [37.889967531684029, 55.752000301821013],
  [37.886285229885907, 55.757512668658947],
  [37.841910416550292, 55.749393227549469],
  [37.842480799145783, 55.755269925606278],
  [37.842287405950593, 55.779841555473283],
  [37.840192154072447, 55.798665401793869],
  [37.837150551060937, 55.822465979755023],
  [37.83264413376601, 55.826881219657068],
  [37.827316245816782, 55.830457506875199],
  [37.785712143625346, 55.85169227552754],
  [37.734429080032562, 55.878013376214042],
  [37.705549958726351, 55.892362212313408],
  [37.698751106678337, 55.893865273221202],
  [37.693243751961489, 55.894744777919982],
  [37.668317328173558, 55.895464658841433],
  [37.639841928426051, 55.89799857336611],
  [37.614893311560621, 55.90232175016228],
  [37.588296740498933, 55.909767106601521],
  [37.580872154976888, 55.91084668554798],
  [37.574524886986147, 55.911023317975953],
  [37.561313661426318, 55.90958789678178],
  [37.534746972821608, 55.906969789140248],
  [37.525713007814417, 55.904930336160042],
  [37.520415889809172, 55.902593956519837],
  [37.498083461347527, 55.892617617231899],
  [37.485121313372559, 55.887848999684458],
  [37.465942482791533, 55.883151467543819],
  [37.451274311290767, 55.882665706602701],
  [37.444871496380578, 55.881775301992313],
  [37.417715047958147, 55.87333365901906],
  [37.407657188056049, 55.869136327527961],
  [37.401617532956109, 55.864436803760327],
  [37.394873671115647, 55.854687934118651],
  [37.391675675010973, 55.855096366155223],
  [37.389775279151912, 55.854138398410832],
  [37.387060416295967, 55.856618774166513],
  [37.378967231003926, 55.853157375185617],
  [37.37550536887769, 55.856433638392723],
  [37.379694359017819, 55.859101587946348],
  [37.376993219661969, 55.868026060688443],
  [37.374399665339183, 55.867622132283117],
  [37.368181370458792, 55.867371264161378],
  [37.360869091061858, 55.8666192791257],
  [37.359177699267583, 55.864796688469347],
  [37.349369593910751, 55.863636569580841],
  [37.347184232039609, 55.862345492005289],
  [37.343284369539283, 55.86524156627307],
  [37.334067917456522, 55.867938034001376],
  [37.332052402449293, 55.867078573334219],
  [37.341795066906222, 55.862949345983509],
  [37.343040854695197, 55.860493203618212],
  [37.341682717845501, 55.860160129448197],
  [37.341235013368468, 55.859353936216152],
  [37.341519096855173, 55.858697065021573],
  [37.34010647153147, 55.855620471938217],
  [37.340085968984972, 55.854032972670829],
  [37.341469992139231, 55.853083731033692],
  [37.340741474883352, 55.851493508153638],
  [37.338278419491488, 55.848975082533222],
  [37.34045535677604, 55.848472445639203],
  [37.339612824575099, 55.847761196266958],
  [37.337402571100583, 55.847956832440673],
  [37.335980543691647, 55.847762977759778],
  [37.332494683083922, 55.845620385088239],
  [37.347645858570893, 55.843952578904897],
  [37.348052829672618, 55.84301615901142],
  [37.343692198373127, 55.842860812605323],
  [37.341706046935059, 55.842141490483343],
  [37.339920192559319, 55.842190947409499],
  [37.339625913071544, 55.840618652494008],
  [37.340306413324988, 55.840066618458849],
  [37.340603582256371, 55.839367043174597],
  [37.348842975061117, 55.834029242540574],
  [37.347683261560192, 55.833024639506888],
  [37.3481092965024, 55.832039434584878],
  [37.34751801361211, 55.831564489686528],
  [37.348848248059262, 55.82951457320835],
  [37.346983092340992, 55.827266726634413],
  [37.34854505687656, 55.825291421649872],
  [37.347480083364047, 55.822604682706498],
  [37.357389216367373, 55.817514205510022],
  [37.352044988529201, 55.816483811783677],
  [37.349213811583297, 55.814392377805639],
  [37.363108425500087, 55.80943420405022],
  [37.366936221854552, 55.807364319834917],
  [37.366102504743623, 55.802894636097363],
  [37.371091440180429, 55.799168849366929],
  [37.377861379615673, 55.795795142480763],
  [37.373486164199889, 55.79051014721388],
  [37.369427725261318, 55.781620221548067],
  [37.369324259318113, 55.76848980646313],
  [37.36950823959306, 55.745932707664679],
];
