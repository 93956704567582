import React from "react";
import DeleteIcon from "src/assets/img/Delete@2x.png";

import "./style.scss";

interface Props {
  text: {
    top: string;
    center: string;
    bottom: string;
  };
  role: string | undefined;
  onRemove?(): void;
}

export const ProfileAddress: React.FC<Props> = ({ text, onRemove, role }) => {
  return (
    <div className="profile__list--item">
      <div className="profile__list--item__container">
        <div className="profile-address">
          <div className="profile-address__name bold-12">{text.top}</div>
          <div className="profile-address__address bold-16 text-shadow-0-0-3">{text.center}</div>
          <div className="profile-address__description medium-12">
            {text.bottom}
          </div>
          {role === 'operator' ? null : 
            (<button className="profile-address__remove" onClick={onRemove}>
              <img src={DeleteIcon} alt="" />
            </button>)
          }
        </div>
      </div>
    </div>
  );
};
