import * as React from "react";
import cn from "classnames";
import "./style.scss";
import classNames from "classnames";

interface Props {
  placeholder?: string;
  value?: string;
  description?: string;
  onClick?(): void;
  error?: string;
}

export const ButtonSelect: React.FC<Props> = ({
  placeholder,
  value,
  description,
  onClick,
  children,
  error,
}) => {
  const hasError = !!error;
  return (
    <div
      onClick={onClick}
      className={cn({
        "button-select": true,
        "button-select--active": !!value,
        "button-select--error": hasError,
      })}
    >
      <div className="button-select__placeholder">{placeholder}</div>
      <div
        className={classNames({
          "button-select__container": true,
          "button-select__container--error": hasError,
        })}
      >
        <span>{value}</span>
      </div>
      <div
        className={classNames({
          "button-select__description": true,
          "button-select__description--error": hasError,
        })}
      >
        {error || description}
      </div>
      {children}
    </div>
  );
};
