import * as React from "react";

import CloseIcon from "src/assets/img/Cancel_gray@2x.png";
interface Props {
  onClose(): void;
  classList?: string;
  icon?: any;
  title: string;
}
export const ModalHeader: React.FC<Props> = ({
  onClose,
  classList = "",
  icon,
  title,
}) => {
  return (
    <div className={`modal-services__header ${classList}`}>
      <div onClick={onClose} className="modal-services__header--close">
        <img src={CloseIcon} alt="" />
      </div>
      <div className="modal-services__header__container">
        <div className="modal-services__header--icon">{icon}</div>
        <div>
          <div className="modal-services__header--title">{title}</div>
        </div>
      </div>
      <slot></slot>
    </div>
  );
};
