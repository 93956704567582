import React from "react";

import { LINKS } from "src/constants/links";
import { useHistory } from "react-router-dom";
import { RouterNames } from "src/routerNames";


import logo from "../../../assets/img/СпотОн_Лого.svg"
import closeIcon from "../../../assets/img/Close@3x.png"
import homeIcon from "../../../assets/img/Home.svg"
import profileIcon from "../../../assets/img/Profile.svg"
import questionIcon from "../../../assets/img/Questions.svg"
import rubleIcon from "../../../assets/img/Ruble.svg"
import documentIcon from "../../../assets/img/Documents.svg"
import messageIcon from "../../../assets/img/Messages.svg"
import logoutIcon from "../../../assets/img/LogOut.svg"
import whatsapp from '../../../assets/img/WhatsApp.svg';
import viber from '../../../assets/img/Viber.svg';
import telegram from '../../../assets/img/telegram.svg';
import { useStores } from "src/hooks/useStores";
import { store } from "src/store";
import { StorageService } from "src/services/storage";

export interface MobileMenuProps {
    visibale: boolean;
    onClick: () => void | undefined
}
export const MobileMenu: React.FC<MobileMenuProps> = ({ visibale, onClick }) => {
    const history = useHistory();
    const [sidebarCLose, setSidebarClose] = React.useState(false)
    const timeout = React.useRef<any>(null);

    const {
        userStore: { user, removeUser },
    } = useStores();

    const close = () => {
        setSidebarClose(true);
        timeout.current = setTimeout(() => onClick(), 500);
    }

    const goToLink = (link: string) => {
        window.open(`${LINKS.main}${link}`);
        onClick()
    };

    const goToHome = () => {
        history.push(RouterNames.Home);
        onClick()
    };

    const logOut = () => {
        StorageService.removeAccess()
        removeUser()
        history.push(RouterNames.Auth)
    };

    React.useEffect(() => {
        return () => {
            timeout.current && clearTimeout(timeout.current);
        };
    }, []);

    return (
        <div className="header--sidebar sidebar">
            <div className="sidebar--background" onClick={close}></div>
            <div className={`sidebar__menu ${sidebarCLose ? "fade-out" : ""}`}>
                <div className="sidebar__menu--header">
                    <a href="/" className="sidebar__menu--header--logo">
                        <img src={logo} alt="" />
                    </a>
                    <div className="sidebar__menu--header--close" onClick={close}>
                        <img src={closeIcon} alt="" />
                    </div>
                </div>
                <div>
                    <div className="header__content">
                        <div className="header__content--title bold-40 text-shadow-0-0-3">
                            {user?.shop_name}
                        </div>
                        <div className="header__content--name bold-30 text-shadow-0-0-3">
                            {user?.last_name} {user?.first_name} {user?.middle_name}
                        </div>
                        <div className="header__content--phone medium-16">{user?.phone}</div>
                    </div>
                </div>
                <div className="sidebar__menu--body menu">
                    <button className="menu--item" onClick={goToHome}>
                        <img className="menu--item--icon" src={homeIcon} alt="" />
                        <span className="menu--item--text">Главная</span>
                    </button>
                    {store.userStore.isAuth ?
                        null : (<button className="menu--item">
                            <img className="menu--item--icon" src={profileIcon} alt="" />
                            <span className="menu--item--text">Вход</span>
                        </button>)
                    }
                    <button className="menu--item" onClick={() => goToLink(LINKS.qa)}>
                        <img className="menu--item--icon" src={questionIcon} alt="" />
                        <span className="menu--item--text">Вопросы и ответы</span>
                    </button>
                    <button className="menu--item" onClick={() => goToLink(LINKS.terms)}>
                        <img className="menu--item--icon" src={rubleIcon} alt="" />
                        <span className="menu--item--text">Стать Исполнителем</span>
                    </button>
                    <button className="menu--item" onClick={() => goToLink(LINKS.terms)}>
                        <img className="menu--item--icon" src={documentIcon} alt="" />
                        <span className="menu--item--text">Правовые документы</span>
                    </button>
                    <button className="menu--item" onClick={() => goToLink(LINKS.contacts)}>
                        <img className="menu--item--icon" src={messageIcon} alt="" />
                        <span className="menu--item--text">Контакты</span>
                    </button>

                    {store.userStore.isAuth ?
                        (
                            <button className="menu--item" onClick={() => logOut()}>
                                <img className="menu--item--icon" src={logoutIcon} alt="" />
                                <span className="menu--item--text">Выйти</span>
                            </button>
                        ) : null
                    }

                    <div className="sidebar__menu--messengers">
                        <a href="https://api.whatsapp.com/send/?phone=79951182441&text&app_absent=0" rel="noopener" target="_blank" className="">
                            <img src={whatsapp} alt="WhatsApp" />
                            <span>WhatsApp</span>
                        </a>
                        <a href="viber://chat?number=%2B79951182441" rel="noopener" target="_blank" className="">
                            <img src={viber} alt="Viber" />
                            <span>Viber</span>
                        </a>
                        <a href="https://t.me/SpotOn_App" rel="noopener" target="_blank" className="">
                            <img src={telegram} alt="Telegram" />
                            <span>Telegram</span>
                        </a>
                    </div>
                    <div className="sidebar__menu--text">
                        <span>Не сообщайте ваш логин и пароль посторонним людям. Сотрудник сервиса СпотОн никогда их не запросит. Если Вы не знаете свои логин и пароль, не получали их или утеряли, то сообщите об этом в службу поддержки.</span>
                        <a className="bold-25" href="tel:74954142021">
                            +7 495 414-20-21
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
