import firebase from "firebase/app";
import "firebase/messaging";
const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as any);

firebase.initializeApp(firebaseConfig);
let messaging: firebase.messaging.Messaging | null = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}
export { messaging };
