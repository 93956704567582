
import React from "react";
import { MobileMenu } from "./MobileMenu";


import logo from "../../../assets/img/СпотОн_Лого.svg"
import burgerIcon from "../../../assets/img/burgericon.svg"

export const AuthMobileHeader: React.FC = () => {
    const [modalVisible, setModalVisible] = React.useState(false)

    const sidebarToggle = () => {
        setModalVisible(!modalVisible)
        if (document.body.style.overflow !== "hidden") {
            document.body.style.overflow = "hidden";
        } 
        else {
            document.body.style.overflow = "scroll";
        }
    }

    return (
        <>
            <div className="auth__header">
                <a href="/" className="auth__header--logo">
                    <img src={logo} alt="" />
                </a>
                <div className="auth__header--burger" onClick={() => sidebarToggle()}>
                    <img src={burgerIcon} alt="" />
                </div>
            </div>
            {modalVisible ? (
                <MobileMenu
                    visibale={modalVisible}
                    onClick={sidebarToggle}
                />
            ) : null}
        </>
    );
};
