import { makeAutoObservable } from "mobx";
import { CreateOperatorData, ShopsApi } from "src/services/api/ShopsApi";
import { UserApi } from "src/services/api/UserApi";
import { StorageService } from "src/services/storage";
import { Address } from "src/types/address";
import { Operator } from "src/types/shops";
import { messaging } from "src/utils/firebase";

const MIN_IN_MS = 60 * 1000;
const SIX_MIN_IN_MS = MIN_IN_MS * 6;

export class UserStore {
  user: Operator | null = null;
  addresses: Address[] = [];
  operators: Operator[] = [];
  constructor() {
    makeAutoObservable(this);
  }
  get isAuth() {
    return !!this.user;
  }

  setUser = (user: Operator) => {
    this.user = user;
  };
  removeUser = () => {
    this.user = null;
  };
  setAddresses = (addresses: Address[]) => {
    this.addresses = addresses;
  };
  addAddress = (address: Address) => {
    this.addresses.push(address);
  };
  setOperators = (operators: Operator[]) => {
    this.operators = operators;
  };
  addOperator = (operator: Operator) => {
    this.operators.push(operator);
  };

  async getAddresses() {
    try {
      const { data } = await ShopsApi.getAddresses();

      this.setAddresses(data);
    } catch (error) {}
  }
  async getUser() {
    try {
      const { data } = await ShopsApi.getMe();
      try {
        await this.checkFirebaseToken(data);
      } catch (error) {}
      this.setUser(data);
    } catch (error) {}
  }

  async checkFirebaseToken(operator: Operator) {
    try {
      const token = await messaging?.getToken();
      const lastUpdateToken = StorageService.getLastUpdateFirebaseToken();
      const currentDate = new Date().getTime();

      if (currentDate - Number(lastUpdateToken) >= SIX_MIN_IN_MS && token) {
        await UserApi.createFcmToken({
          firebase_token: token,
          role: operator.role,
        });
      }
    } catch (error) {}
  }
  async getOperators() {
    try {
      const { data } = await ShopsApi.getOperators();
      this.setOperators(data);
    } catch (error) {}
  }
  async getUserData() {
    return Promise.all([this.getAddresses(), this.getUserInfo()]);
  }

  async getUserInfo() {
    return Promise.all([this.getUser(), this.getOperators()]);
  }

  async createOperator(data: CreateOperatorData) {
    return ShopsApi.createOperator(data).then(({ data }) => {
      this.addOperator(data);
    });
  }

  async createAddress(data: Address) {
    return ShopsApi.createAddress(data).then(({ data }) => {
      this.addAddress(data);
    });
  }
}
