import React from "react";
import Logo from "../../assets/img/LogoSpotOn.svg";
import { ReactSVG } from "react-svg";
import "./style.scss";
import { RouterNames } from "src/routerNames";
import { useHistory } from "react-router";
import { LINKS } from "src/constants/links";

export const Footer: React.FC = () => {
  const history = useHistory();
  const goToLink = (link: string) => {
    window.open(`${LINKS.main}${link}`);
  };

  const goToHome = () => {
    history.push(RouterNames.Home);
  };
  return (
    <div className="main-content-horiz main-content-horiz-footer">
      <div className="c-footer content-middle main-content-horiz-box">
        <ReactSVG onClick={goToHome} class="c-footer-logo" src={Logo} width={150} height={46} />
        <div className="link-block c-footer-desktop mb-3">
          <div className="link-block-item-sideward">
            <button
              onClick={() => goToLink(LINKS.qa)}
              type="button"
              className="btn btn-link link-block-item"
            >
              Вопросы и ответы
            </button>
            <button
              onClick={() => goToLink(LINKS.terms)}
              type="button"
              className="btn btn-link link-block-item"
            >
              Стать исполнителем
            </button>
          </div>

          <div className="link-block-item-sideward">
            <button
              onClick={() => goToLink(LINKS.terms)}
              type="button"
              className="btn btn-link link-block-item"
            >
              Правовые документы
            </button>
            <button
              onClick={() => goToLink(LINKS.contacts)}
              type="button"
              className="btn btn-link link-block-item"
            >
              Контакты
            </button>
          </div>
        </div>
        <div className="c-footer-copyright-box">
          <p className="c-footer-copyright">
            <strong>&#169; 2020 - 2021 SpotOn. Все права защищены.</strong>
            <br />
            ООО “СПОТ“, ИНН 7720480378, ОГРН 1197746539715 <br />
            Юридический адрес (фактический адрес): 111524, г. Москва, ул.
            Электродная, д. 2, стр. 12-13-14, э. 5, пом. III, ком. 23
          </p>
        </div>
        <div className="protected-text-container">
          <span className="protected-text">
            Этот сайт защищен reCAPTCHA и применяются{" "}
            <a target="_blank" href="https://policies.google.com/privacy">
              Политика конфиденциальности
            </a>{" "}
            и{" "}
            <a target="_blank" href="https://policies.google.com/terms">
              Условия обслуживания
            </a>{" "}
            Google.
          </span>
        </div>
      </div>
    </div>
  );
};
