import * as React from "react";

import { format } from "date-fns";
import { DAY_LIST } from "src/pages/create/components/SlotsModal/SlotsModal";
import { Order } from "src/types/orders";
import { addressFull } from "src/utils/addressFull";
import { convertDateToDay } from "src/utils/conertDateToDay";
import CancelIcon from "src/assets/img/Cancel_gray@2x.png";
import DeleteIcon from "src/assets/img/Delete_blue_icon@2x.png";
import OkIcon from "src/assets/img/Ok@2x.png";

import "./style.scss";
import { cutObjectKeyNames } from "src/utils/cutObjectKeyNames";
import { getOrderStatus } from "src/helpers/getOrderStatus";
import { cancelable } from "src/helpers/cancelable";

interface Props {
  orders: Order[];
  onCancel(order: Order): void;
  onOk(order: Order): void;
}

export const OrdersTable: React.FC<Props> = ({ orders, onCancel, onOk }) => {
  return (
    <table className="order--table">
      <thead>
        <tr>
          <td>Ваш номер</td>
          <td>Дата и время</td>
          <td>Статус</td>
          <td>Телефон заказчика</td>
          <td>Магазин и сумма заказа</td>
          <td>Адрес доставки</td>
          <td>Время посещения магазина и время доставки</td>
          <td>Оператор</td>
          <td>Номер доставки</td>
          <td>Отмена заказа</td>
        </tr>
      </thead>
      <tbody>
        {orders.map((item) => {
          const operator = item.shop_operator;
          const order = item.order;
          const place = item.order.shopping_addresses?.length
            ? item.order.shopping_addresses[0]
            : null;
          const day = convertDateToDay(
            new Date(order.deliveryorder?.delivery_date)
          );
          const dayText =
            DAY_LIST.find((item) => item.day === day)?.title || day;
          const customerPhone = item.order?.customer_phone;
          return (
            <React.Fragment key={order.id}>
              <tr className={item.require_attention ? "active" : ""}>
                <td>{item.number_in_shop}</td>
                <td>
                  {format(new Date(item.created_at), `HH:mm`)}
                  <br />
                  {format(new Date(item.created_at), `dd-MM-yyy`)}
                </td>
                <td>{getOrderStatus(order.status)}</td>
                <td>{customerPhone}</td>
                <td>
                  {place?.place_name || "Без имени"} |{" "}
                  {place?.amount.toFixed(2)} ₽
                  <div className="regular-12 order--table__description">
                    {addressFull({
                      apartment: place?.apartment,
                      floor: place?.floor,
                      description: place?.description,
                    })}
                  </div>
                </td>
                <td>
                  {order?.deliveryorder?.delivery_address_street},{" "}
                  {order?.deliveryorder?.delivery_address_number}
                  <div className="regular-12 order--table__description">
                    {order.deliveryorder.delivery_address_street
                      ? addressFull(
                          cutObjectKeyNames(
                            order.deliveryorder,
                            "delivery_address_"
                          )
                        )
                      : ""}
                  </div>
                </td>
                <td>
                  ≈ {order?.performer_at_first_point_from} -{" "}
                  {order?.performer_at_first_point_to}
                  <div className="regular-12 order--table__description">
                    {order?.deliveryorder?.time_from_h} -{" "}
                    {order?.deliveryorder?.time_to_h} {dayText}
                  </div>
                </td>
                <td>
                  {operator?.last_name} {operator?.first_name}{" "}
                  {operator?.middle_name}
                </td>
                <td>
                  {order?.id}
                  <br />
                  {order?.amounts_data?.service_amount.toFixed(2)}{" "}
                  <span className="rouble">₽</span>
                </td>
                <td>
                  <div
                    onClick={() => onCancel(item)}
                    className="order--table__cancel"
                  >
                    {cancelable(item.order.status) ? (
                      <img
                        className="order--table__cancel--active"
                        src={DeleteIcon}
                        alt=""
                      />
                    ) : (
                      <img
                        className="order--table__cancel--icon"
                        src={CancelIcon}
                        alt=""
                      />
                    )}
                  </div>
                  {item.require_attention ? (
                    <div
                      onClick={() => onOk(item)}
                      className="order--table__attention"
                    >
                      <img alt="" src={OkIcon} />
                    </div>
                  ) : null}
                </td>
              </tr>
              <tr className="spacer" />
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
