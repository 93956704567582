import axios from "axios";
import { store } from "src/store";
import { StorageService } from "../storage";

export const AxiosApi = axios.create({
  baseURL: process.env.REACT_APP_URL,
});
export function setToken(token: string) {
  AxiosApi.defaults.headers.common.Authorization = token
    ? `Bearer ${token}`
    : null;
}

AxiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      StorageService.removeAccess();
      StorageService.removeLastUpdateFirebaseToken();
      store.userStore.removeUser();
    }
    store.modalStore.openModal({
      title: "Что-то пошло не так",
      messages: [
        error?.response?.message ||
          "Попробуйте еще раз. Если не получится - сообщите Оператору 💁‍♀️",
      ],
      button: {
        title: "Бывает",
      },
      icon: "attention",
    });

    return Promise.reject(error);
  }
);
