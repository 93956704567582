import React from "react";
import { TextFieldAutocomplete } from "./TextFieldAutocomplete";
import InputMask from "react-input-mask";
import cn from "classnames";
import "./style.scss";

export type Autocomplete = {
  top: string;
  bottom: string;
  id?: any;
};
export interface TextFieldProps {
  value?: string;
  type?: string;
  inputMode?: any;
  description?: string;
  placeholder?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onChangeText?: (text: string) => void;
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
  onSelectItem?: (value?: any) => void;
  autocompleteList?: Autocomplete[];
  mask?: string;
  customInput?: React.ReactNode;
  error?: string;
  textVariant?: "bold";
  customFocus?: boolean;
  testId?: string;
  autoComplete?: string;
}

export const TextField: React.FC<TextFieldProps> = ({
  type = "text",
  value,
  inputMode,
  description,
  placeholder,
  onChange,
  onChangeText,
  onSelectItem,
  onBlur,
  autocompleteList = [],
  mask,
  customInput,
  error,
  textVariant,
  customFocus,
  testId,
  autoComplete,
}) => {
  const hasError = !!error;
  const timeout = React.useRef<any>(null);

  const [focus, setFocus] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState(false);
  const autocomplete = React.useMemo(() => {
    if (!suggestions) {
      return [];
    }
    return value
      ? autocompleteList?.filter(
          (item) => item.top.toLowerCase().indexOf(value) !== -1
        )
      : autocompleteList;
  }, [autocompleteList, suggestions, value]);
  React.useEffect(() => {
    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  }, []);
  return (
    <div
      onClick={() => {
        setSuggestions(true);
      }}
      className="text-field"
    >
      <div
        className={cn({
          "text-field__container": true,
          "text-field__container--list": focus && autocomplete?.length,
          "text-field__container--error": hasError,
          "text-field__container--bold": textVariant === "bold",
        })}
        data-test-id={process.env.REACT_APP_TEST_ID === "show" ? testId : ""}
      >
        {customInput ||
          (mask ? (
            <InputMask
              mask={mask}
              value={value}
              type={type}
              onChange={(event) =>
                (onChange && onChange(event)) ||
                (onChangeText && onChangeText(event.target.value))
              }
              onFocus={() => setFocus(true)}
              onBlur={(event) => {
                setFocus(false);

                onBlur && onBlur(event);
              }}
              // @ts-ignore
              maskChar=" "
              formatChars={{
                "#": "[0-9]",
                a: "[A-Za-z]",
                "*": "[A-Za-z0-9]",
              }}
            />
          ) : (
            <input
              type={type}
              value={value}
              inputMode={inputMode}
              onChange={(event) => {
                (onChange && onChange(event)) ||
                  (onChangeText && onChangeText(event.target.value));
              }}
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={(event) => {
                setFocus(false);
                onBlur && onBlur(event);
                timeout.current = setTimeout(() => setSuggestions(false), 200);
              }}
              autoComplete="chrome-off"
            />
          ))}
        {autocomplete.length > 0 && (
          <div className="text-field--autocomplete">
            {autocomplete.map((item, index) => {
              return (
                <TextFieldAutocomplete
                  key={item.bottom + item.top + index}
                  top={item.top}
                  bottom={item.bottom}
                  onClick={() => {
                    onChangeText && onChangeText(item.top);
                    onSelectItem && onSelectItem(item.id);
                    setSuggestions(false);
                  }}
                />
              );
            })}
          </div>
        )}
      </div>
      <div
        className={cn({
          "text-field--placeholder": true,
          "text-field--placeholder__active": !!value || focus || customFocus,
          "text-field--placeholder__error": hasError,
        })}
      >
        {placeholder}
      </div>
      <div
        className={cn({
          "text-field--description": true,
          "text-field--description__error": hasError,
        })}
      >
        {error || description}
      </div>
    </div>
  );
};
