const addComma = (text: string) => {
  return text ? `, ${text}` : "";
};

const findComponent = (
  components: google.maps.GeocoderAddressComponent[] = [],
  values: string[] = []
) => {
  return components?.find((item) => {
    for (let i = 0; i < item.types.length; i++) {
      const element = item.types[i];
      if (values.includes(element)) {
        return true;
      }
    }
    return false;
  });
};

export function formatAddressText(street?: string, number?: string) {
  return `${street || ""}${addComma(number || "")}`;
}

export function formatAddressComponents(
  components: google.maps.GeocoderAddressComponent[] = []
) {
  const street = findComponent(components, [
    "route",
    "city",
    "locality",
    "administrative_area_level_3",
  ]);
  const number = findComponent(components, [
    "street_number",
    "premise",
    "subpremise",
    "establishment",
    "point_of_interest",
  ]);

  return {
    street,
    number,
  };
}
