import React from "react";

import whatsapp from '../../../assets/img/WhatsApp.svg';
import viber from '../../../assets/img/Viber.svg';
import telegram from '../../../assets/img/telegram.svg';

export const AuthFooter: React.FC = () => {
  return (
    <footer className="auth__footer">
      <div className="auth__footer--description regular-14">
        Не сообщайте ваш логин и пароль посторонним людям. Сотрудник сервиса
        SpotOn никогда их не запросит. Если Вы не знаете свои логин и пароль, не
        получали их или утеряли, то сообщите об этом в службу поддержки.
      </div>
      <div className="auth__footer--messengers">
        <a href="https://api.whatsapp.com/send/?phone=79951182441&text&app_absent=0" rel="noopener" target="_blank" className="auth__footer--messengers--item">
          <img src={whatsapp} alt="WhatsApp" />
          <span>WhatsApp</span>
        </a>
        <a href="viber://chat?number=%2B79951182441" rel="noopener" target="_blank" className="auth__footer--messengers--item">
          <img src={viber} alt="Viber" />
          <span>Viber</span>
        </a>
        <a href="https://t.me/SpotOn_App" rel="noopener" target="_blank" className="auth__footer--messengers--item">
          <img src={telegram} alt="Telegram" />
          <span>Telegram</span>
        </a>
      </div>
      <div className="auth__footer--phone">
        <a className="bold-25" href="tel:84954142022">
          8 495 414-20-22
        </a>
        <div className="auth__footer--phone__text medium-16">
          если нужна помощь
        </div>
      </div>
    </footer>
  );
};
