import React from "react";
import { TextFieldAutocomplete } from "./TextFieldAutocomplete";
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import cn from "classnames";
import "./style.scss";
import { PlaceAddress } from "src/types/address";
import {
  formatAddressComponents,
  formatAddressText,
} from "src/helpers/formatAddress";

export interface TextFieldProps {
  placeholder: string;
  onSelect(address: PlaceAddress, raw: google.maps.GeocoderResult): void;
  error?: string;
  onBlur?(): void;
  autoComplete?: string;
  testId?: string;
}

export const TextFieldPlaces: React.FC<TextFieldProps> = ({
  placeholder,
  onSelect,
  error,
  onBlur,
  autoComplete,
  testId,
}) => {
  const hasError = !!error;
  const [value, setValue] = React.useState("");
  const [focus, setFocus] = React.useState(false);

  const handleSelect = async (_: string, id: string) => {
    try {
      const [place] = await geocodeByPlaceId(id);
      const location = await getLatLng(place);
      console.log(place);
      const { street, number } = formatAddressComponents(
        place.address_components
      );

      setValue(formatAddressText(street?.short_name, number?.short_name));
      if (street && number) {
        onSelect(
          {
            number: number.short_name || "",
            street: street.short_name || "",
            latitude: location.lat.toString(),
            longitude: location.lng.toString(),
            google_address_data: place,
          },
          place
        );
      }
    } catch (error) {}
  };
  return (
    <PlacesAutocomplete
      value={value}
      onChange={setValue}
      onSelect={handleSelect}
      searchOptions={{
        componentRestrictions: {
          country: "RU",
        },
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="text-field">
          <div
            className={cn({
              "text-field__container": true,
              "text-field__container--active": focus,
              "text-field__container--list": suggestions?.length,
              "text-field__container--error": hasError,
            })}
            data-test-id={
              process.env.REACT_APP_TEST_ID === "show" ? testId : ""
            }
          >
            <input
              {...getInputProps()}
              onFocus={() => setFocus(true)}
              onBlur={() => {
                onBlur && onBlur();
                setFocus(false);
              }}
              autoComplete={autoComplete || `on`}
            />

            {suggestions.length > 0 && (
              <div className="text-field--autocomplete">
                {suggestions.slice(0, 3).map((item) => {
                  const formatted = item.formattedSuggestion;
                  return (
                    <div {...getSuggestionItemProps(item)} key={item.placeId}>
                      <TextFieldAutocomplete
                        top={formatted.mainText}
                        bottom={formatted.secondaryText}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div
            className={cn({
              "text-field--placeholder": true,
              "text-field--placeholder__active": !!value || focus,
            })}
          >
            {placeholder}
          </div>
          <div
            className={cn({
              "text-field--description": true,
              "text-field--description__error": hasError,
            })}
          >
            {error}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};
