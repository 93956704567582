import { STORAGE_NAMES } from "./storageName";

export const StorageService = {
  get access() {
    return localStorage.getItem(STORAGE_NAMES.ACCESS_TOKEN);
  },
  setAccess(access: string) {
    return localStorage.setItem(STORAGE_NAMES.ACCESS_TOKEN, access);
  },

  setAccountUsername(account: string) {
    return localStorage.setItem(STORAGE_NAMES.ACCOUNT_USERNAME, account);
  },
  get accountUsername() {
    return localStorage.getItem(STORAGE_NAMES.ACCOUNT_USERNAME);
  },
  removeAccess() {
    return localStorage.removeItem(STORAGE_NAMES.ACCESS_TOKEN);
  },

  getLastUpdateFirebaseToken() {
    return localStorage.getItem(STORAGE_NAMES.LAST_UPDATE_FIREBASE_TOKEN);
  },
  setLastUpdateFirebaseToken(date: number) {
    return localStorage.setItem(
      STORAGE_NAMES.LAST_UPDATE_FIREBASE_TOKEN,
      String(date)
    );
  },
  removeLastUpdateFirebaseToken() {
    return localStorage.removeItem(STORAGE_NAMES.LAST_UPDATE_FIREBASE_TOKEN);
  },
};
