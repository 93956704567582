import React from "react";

import "./sass/style.scss";
import { StoreProvider } from "./contexts/StoreContext";
import { store } from "./store";
import { AppRouter } from "./router/AppRouter";
import { messaging } from "./utils/firebase";
import { Header } from "./components/Header";
import { observer } from "mobx-react";
import { AppModal } from "./components/AppModal";

const App: React.FC = observer(() => {
  const registerNotificationHandler = () => {
    messaging?.onMessage((payload) => {
      const data = { ...payload.notification, ...payload.data };
      const notificationOptions = {
        body: data.body,
        icon: data.image,
        image: data.image,
        requireInteraction: true,
        data,
      };
      return new Notification(
        payload.notification?.title || "",
        notificationOptions
      );
    });
  };

  React.useEffect(() => {
    registerNotificationHandler();
  }, []);
  return (
    <StoreProvider store={store}>
      <div className="App">
        {store.userStore?.user && <Header />}

        <div id="content">
          <AppRouter />
        </div>
        {store.modalStore.visible && <AppModal />}
      </div>
    </StoreProvider>
  );
});

export default App;
