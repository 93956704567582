import React from "react";
import { useHistory } from "react-router-dom";
import { RouterNames } from "src/routerNames";
import { HomeItem } from "./components/HomeItem";

import AddIcon from "../../assets/img/Add@2x.png";
import BuyIcon from "../../assets/img/Buy@2x.png";
import HomeIcon from "../../assets/img/Home@2x.png";

import "./style.scss";
import { observer } from "mobx-react";
import { useStores } from "src/hooks/useStores";
import { AuthFooter } from "../auth/components/AuthFooter";
const TEXT_ADDRESS_FILL = `Сначала зайдите в «Профиль» и заполните адреса магазинов`;
export const Home: React.FC = observer(() => {
  const history = useHistory();
  const { userStore } = useStores();
  const [createClicked, setcreateClicked] = React.useState(false);
  const goToCreate = () => {
    setcreateClicked(true)
    if (userStore.addresses.length) {
      history.push(RouterNames.Create);
    }
  };

  const goToOrders = () => {
    history.push(RouterNames.Orders);
  };
  const goToProfile = () => {
    history.push(RouterNames.Profile);
  };

  return (
    <div>
      <div className="home page">
        <HomeItem
          icon={<img src={AddIcon} alt="" />}
          onClick={goToCreate}
          title="Создать"
          description={!userStore.addresses.length && createClicked ? TEXT_ADDRESS_FILL : ""}
          testId="create_btn"
        />
        <HomeItem
          icon={<img src={BuyIcon} alt="" />}
          onClick={goToOrders}
          title="Заказы"
          testId="orders_btn"
        />
        <HomeItem
          icon={<img src={HomeIcon} alt="" />}
          onClick={goToProfile}
          title="Профиль"
          testId="profile_btn"
        />
      </div>
      <AuthFooter />
    </div>
  );
});
