import { format, addDays } from "date-fns";
export function convertDayToDate(day: "today" | "tomorrow" | "after_tomorrow") {
  let date = new Date();
  let addedDays = 0;

  switch (day) {
    case "tomorrow": {
      addedDays = 1;
      break;
    }
    case "after_tomorrow": {
      addedDays = 2;
      break;
    }
  }

  return format(addDays(date, addedDays), "yyyy-MM-dd");
}
