import * as React from "react";
import cn from "classnames";
import "./style.scss";

interface Props {
  active: number;
  count: number;
  changePage(page: number): void;
}

export const Pagination: React.FC<Props> = ({ active, count, changePage }) => {
  const pages = () => {
    let list = [];

    for (let i = 0; i < count; i++) {
      const page = i + 1;
      if (i === 4) {
        list.push(count);
        break;
      } else {
        list.push(page);
      }
    }
    return list;
  };
  return (
    <div className="pagination">
      {pages().map((_, index) => {
        const page = index + 1;
        return (
          <div
            key={page}
            onClick={() => {
              if (active !== page) {
                changePage(page);
              }
            }}
            className={cn({
              "pagination--page": true,
              "pagination--page__active": page === active,
            })}
          >
            {page}
          </div>
        );
      })}
    </div>
  );
};
