import React from "react";

import cn from "classnames";
import "./style.scss";

export type TabItem = {
  value: any;
  text: string;
};

interface Props {
  list: TabItem[];
  onChange(tab: string): void;
  active: string[];
}
export const StatusTab: React.FC<Props> = ({ list, active, onChange }) => {
  return (
    <div className="tabs">
      {list.map((item) => {
        return (
          <div
            key={item.value}
            onClick={() => onChange(item.value)}
            className={cn({
              "tabs--item bold-12": true,
              "tabs--item__active": active.includes(item.value),
            })}
          >
            {item.text}
          </div>
        );
      })}
    </div>
  );
};
