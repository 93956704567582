import { Account } from "src/types/account";
import { AxiosApi } from "./AxiosApi";

export type CreateFcmData = {
  role: string;
  firebase_token: string;
};

export const UserApi = {
  getAccount(id: string) {
    return AxiosApi.get<Account>(`/user/account/${id}/`);
  },
  createFcmToken(data: CreateFcmData) {
    return AxiosApi.post("/user/account/create_fcm_token/", data);
  },
};
