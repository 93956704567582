import React from "react";
import AddIcon from "src/assets/img/Add.png";
import CancelIcon from "src/assets/img/Close@3x.png";


interface Props {
  onClick?(): void;
  title: string;
  visible?: boolean;
  testId?: string;
}

export const ProfileAdd: React.FC<Props> = ({
  onClick,
  title,
  visible,
  testId,
}) => {
  return (
    <div className="profile__list--item">
      <div className="profile__list--item__container">
        <div
          onClick={onClick}
          className="profile__list--add"
          data-test-id={process.env.REACT_APP_TEST_ID === "show" ? testId : ""}
        >
          <span className={`profile__list--add__title bold-20 ${visible ? 'active' : ''}`}>{title}</span>
          <div className="profile__list--add__icon">
            <img src={visible ? CancelIcon : AddIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
