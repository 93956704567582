import { makeAutoObservable } from "mobx";
import { setToken } from "src/services/api/AxiosApi";
import { StorageService } from "src/services/storage";
import { ModalStore } from "./modules/ModalStore";
import { UserStore } from "./modules/UserStore";

export class RootStore {
  initialized = false;
  userStore: UserStore;
  modalStore: ModalStore;

  constructor() {
    this.userStore = new UserStore();
    this.modalStore = new ModalStore();

    makeAutoObservable(this);
  }

  async init() {
    try {
      const accessToken = StorageService.access;

      if (accessToken) {
        setToken(accessToken);
        await this.userStore.getUserData();
      }
    } catch (error) {
    } finally {
      this.initialized = true;
      console.log("inited");
    }
  }
}
