import * as React from "react";
import { useHistory } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";

import { AppButtonCenter } from "../../components/AppButtonCenter";
import { AuthMobileHeader } from "./components/AuthMobileHeader";
import { AuthFooter } from "./components/AuthFooter";
import { TextField } from "src/components/TextField";
import { AuthApi } from "src/services/api/AuthApi";
import { setToken } from "src/services/api/AxiosApi";
import { StorageService } from "src/services/storage";
import { RouterNames } from "src/routerNames";
import { store } from "src/store";
import { clearPhone } from "src/utils/clearPhone";
import { messaging } from "src/utils/firebase";
import { ReactComponent as SpotOnIcon } from "../../assets/img/SpotOn_Icon.svg";
import "./style.scss";
import { LINKS } from "src/constants/links";
import { PHONE_LENGTH } from "src/constants/phone";

enum AuthTabEnum {
  PHONE = "PHONE",
  CODE = "CODE",
}
const RUSSIAN_CODE = "7";
export const Auth: React.FC = () => {
  const history = useHistory();
  const interval = React.useRef<any>(null);
  const [phone, setPhone] = React.useState("");
  const [code, setCode] = React.useState<string>("");
  const [codeError, setCodeError] = React.useState("");
  const [tab, setTab] = React.useState<keyof typeof AuthTabEnum>(
    AuthTabEnum.PHONE
  );
  const [codeLoading, setCodeLoading] = React.useState(false);
  const [seconds, setSeconds] = React.useState(60);
  const [authError, setAuthError] = React.useState("");
  const clearlyPhone = React.useMemo(() => {
    return clearPhone(phone);
  }, [phone]);
  const plusPhone = `+${clearlyPhone}`;
  const username = `op${clearlyPhone}`;
  const timer = `0${seconds}`.slice(-2);

  const startTimer = () => {
    setSeconds(60);
    interval.current = setInterval(() => {
      setSeconds((prev) => prev - 1);
    }, 1000);
  };

  const stopTimer = () => {
    interval.current && clearInterval(interval.current);
  };
  const generatePassword = () => {
    (grecaptcha as any).ready(() => {
      grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_KEY as string, {
          action: "sendcode",
        })
        .then(function (token) {
          setCodeLoading(true);
          AuthApi.generatePassword({
            role: "shop_operator",
            username,
            phone: plusPhone,
            "g-recaptcha-response": token,
          })
            .then(() => {
              startTimer();
              setTab(AuthTabEnum.CODE);
            })
            .catch((error) => {
              if (error.response.status === 404) {
                setAuthError(
                  "Телефон не найден. Обратитесь к Вашему администратору"
                );
                store.modalStore.toggleVisible();
              } else {
                setAuthError("");
              }
            })
            .finally(() => {
              setCodeLoading(false);
            });
        });
    });
  };
  const resend = () => {
    if (seconds === 0) {
      generatePassword();
    }
  };

  const login = async (val?: string) => {
    try {
      setCodeLoading(true);

      const firebaseToken = (await messaging?.getToken()) || "";
      const { data } = await AuthApi.login({
        password: val || code,
        phone: plusPhone,
        firebase_token: firebaseToken,
      });

      setToken(data.access);
      StorageService.setAccess(data.access);
      StorageService.setAccountUsername(username);
      await store.userStore.getUserData();

      history.push(RouterNames.Home);
    } catch (error) {
      if (error) {
        setCodeError("Неверный код. Повторите попытку");
        store.modalStore.toggleVisible();
      }
    } finally {
      setCodeLoading(false);
    }
  };

  const onClick = () => {
    switch (tab) {
      case AuthTabEnum.PHONE: {
        generatePassword();
        break;
      }
      case AuthTabEnum.CODE: {
        login();
        break;
      }
    }
  };
  const onChangePhone = (value: string) => {
    const length = value.length;
    const firstLetter = length ? value[1] : "";
    if (firstLetter !== RUSSIAN_CODE && firstLetter !== " ") {
      const slicedPhone = value.slice(2);
      let phone = RUSSIAN_CODE + slicedPhone;
      setPhone(phone);
    }
    else{
      setPhone(value);
    }
  };

  const phoneError = React.useMemo(() => {
    let error = "";
    if (clearlyPhone.length > 1 && clearlyPhone[1] !== "9") {
      error = "Некорректный номер телефона";
    }
    if (clearlyPhone.length && clearlyPhone.length !== PHONE_LENGTH) {
      error = "Введите номер полностью";
    }
    return error;
  }, [clearlyPhone]);

  React.useEffect(() => {
    if (clearlyPhone.length < 11) {
      setAuthError("");
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [clearlyPhone]);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [code]);

  React.useEffect(() => {
    if (!seconds) {
      stopTimer();
    }
  }, [seconds]);
  return (
    <div className="auth">
      <AuthMobileHeader />
      <div className="auth__content">
        <div className="auth__content--logo">
          <SpotOnIcon />
        </div>
        <div className="auth__content--title">
          <div className="bold-40">Вход в сервис</div>
          <div className="bold-30">для магазинов</div>
        </div>
        <div className="auth__content--input">
          {tab === AuthTabEnum.PHONE ? (
            <TextField
              mask="+# (###) ###-##-##"
              type="tel"
              value={phone}
              error={phoneError || authError}
              onChangeText={onChangePhone}
              textVariant="bold"
              placeholder="Телефон"
            />
          ) : (
            <div className="auth__content--code">
              <div onClick={resend} className="auth__content--code--timer">
                {seconds > 0
                  ? `Отправить повторно можно через 00:${timer} сек`
                  : "Отправить повторно"}
              </div>
              <ReactCodeInput
                className="code-field"
                autoFocus
                fields={4}
                fieldHeight={90}
                fieldWidth={90}
                loading={codeLoading}
                onChange={(val) => setCode(val)}
                onComplete={login}
              />
              <div className="auth__content--code--error">{codeError}</div>
            </div>
          )}
        </div>
        <AppButtonCenter
          disabled={codeLoading}
          onClick={onClick}
          title={tab === AuthTabEnum.PHONE ? "Готово" : "Войти"}
        />
        {tab === AuthTabEnum.PHONE && (
          <div className="auth__content--terms-text bold-14">
            Нажимая кнопку «Готово» Вы соглашаешься с условиями{" "}
            <u onClick={() => window.open(`${LINKS.main}${LINKS.terms}`)}>
              правовых документов
            </u>
          </div>
        )}
      </div>
      <AuthFooter />
    </div>
  );
};
