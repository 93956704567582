import React from "react";
import { observer } from "mobx-react";

import { Switch, Route, Redirect } from "react-router-dom";
import { useStores } from "src/hooks/useStores";
import { Auth } from "src/pages/auth/Auth";
import { Create } from "src/pages/create/Create";
import { Home } from "src/pages/home/Home";
import { Orders } from "src/pages/order/Orders";
import { Profile } from "src/pages/profile/Profile";
import { RouterNames } from "src/routerNames";
import { PrivateRoute } from "./PrivateRoute";
import { Footer } from "src/components/Footer";

export const AppRouter: React.FC = observer(() => {
  const store = useStores();

  if (!store.initialized) {
    return null;
  }
  return (
    <>
      <Switch>
        <Route exact path={RouterNames.Auth}>
          {store.userStore.isAuth ? (
            <Redirect
              to={{
                pathname: RouterNames.Home,
              }}
            />
          ) : (
            <Auth />
          )}
        </Route>
        <PrivateRoute path={RouterNames.Orders}>
          <Orders />
        </PrivateRoute>
        <PrivateRoute path={RouterNames.Create}>
          <Create />
        </PrivateRoute>
        <PrivateRoute path={RouterNames.Profile}>
          <Profile />
        </PrivateRoute>
        <PrivateRoute path={RouterNames.Home}>
          <Home />
        </PrivateRoute>
      </Switch>
      <Footer />
    </>
  );
});
