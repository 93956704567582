import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { RouterNames } from "../routerNames";
import { useStores } from "../hooks/useStores";

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { userStore } = useStores();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        userStore.isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: RouterNames.Auth,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
