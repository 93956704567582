import React from "react";
import "./style.scss";

interface Props {
  leftText?: string | React.ReactNode;
  rightText?: string | React.ReactNode;
  leftBottomText?: string | React.ReactNode;
  onClick?(): void;
  disabled?: boolean;
}

export const AppButton: React.FC<Props> = ({
  rightText,
  leftText,
  leftBottomText,
  onClick,
  disabled,
}) => {
  return (
    <button disabled={disabled} onClick={onClick} className="app-button">
      <div className="app-button__left bold-20">
        {leftText}
        <div className="app-button__left medium-12">{leftBottomText}</div>
      </div>
      <div className="app-button-right">{rightText}</div>
    </button>
  );
};
