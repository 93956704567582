import * as React from "react";

import { TextField } from "../../components/TextField";
import { ButtonSelect } from "../../components/ButtonSelect";
import CurrencyInput from "react-currency-input-field";

import { CreateData } from "./Create.data";
import { AppButton } from "src/components/AppButton";
import { useStores } from "src/hooks/useStores";
import { Address, PlaceAddress } from "src/types/address";
import { mkadPolygonCheck } from "src/helpers/mkadPolygonCheck";
import { TextFieldPlaces } from "src/components/TextField/TextFieldPlaces";
import { DAY_LIST, SlotsModal } from "./components/SlotsModal/SlotsModal";
import {
  CalculateData,
  OrdersApi,
  SlotResponse,
} from "src/services/api/OrdersApi";
import { DayLiteral, SelectSlot } from "src/types/slots";
import "./style.scss";
import { convertDayToDate } from "src/utils/convertDayToDate";
import { clearPhone } from "src/utils/clearPhone";
import { PHONE_LENGTH } from "src/constants/phone";
import { useHistory } from "react-router";
import { RouterNames } from "src/routerNames";
import { validateRequired } from "src/validation/validateRequired";
import { AppButtonCenter } from "../../components/AppButtonCenter";
import { Autocomplete } from "src/components/TextField/TextField";
import { MobileSlots } from "./components/SlotsModal/MobileSlots";
import { Link } from "react-router-dom";

type AddressLiteral = keyof Address;

const RUSSIAN_CODE = "7";
export const Create: React.FC = () => {
  const { userStore, modalStore } = useStores();
  const history = useHistory();
  const [errors, setErrors] = React.useState({
    address: "",
    customerAddress: "",
    amount: "",
    number: "",
    phone: "",
    recipientPhone: "",
  });
  const [customerAddressKey, setCustomerAddressKey] = React.useState(0);
  const [focus, setFocus] = React.useState(false);
  const [slotLoading, setSlotLoading] = React.useState(false);
  const [selectedSlot, setSelectedSlot] = React.useState<SelectSlot | null>();
  const [slots, setSlots] = React.useState<SlotResponse | null>(null);
  const [address, setAddress] = React.useState<Address | null>(
    userStore.addresses[0]
  );
  const [number, setNumber] = React.useState<string>("б/н");
  const [detail, setDetail] = React.useState<string>("");
  const [customerAddress, setCustomerAddress] = React.useState<Address>({
    place_name: "",
    latitude: "",
    longitude: "",
    street: "",
    number: "",
    country: "",
    city: "",
    apartment: "",
    floor: "",
    description: "",
    entrance: "",
    intercom: "",
  });
  const [amount, setAmount] = React.useState<string | undefined>("0");
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [recipient, setRecipient] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [recipientPhone, setRecipientPhone] = React.useState("");
  const [serviceCost, setServiceCost] = React.useState(0);
  const [slotsVisible, setSlotsVisible] = React.useState(false);
  const [thermobag, setThermobag] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [disabledRecipient, setDisabledRecipient] = React.useState(true);
  const [disablePayment, setDisablePayment] = React.useState(true);

  const clearlyPhone = clearPhone(phone);
  const clearlyRecipientPhone = clearPhone(recipientPhone);

  const formattedNumber = userStore.user?.phone.replace(
    new RegExp(`(\\d{3})(\\d{3})(\\d{2})(\\d{2}$)`),
    " ($1) $2-$3-$4"
  );

  const deliveryPoint = {
    latitude: Number(customerAddress.latitude),
    longitude: Number(customerAddress.longitude),
  };
  const placePoint = {
    latitude: Number(address?.latitude),
    longitude: Number(address?.longitude),
  };
  const deliveryDate = convertDayToDate(selectedSlot?.day || "today");
  const handleErrors = (errorList: Partial<typeof errors>) => {
    setErrors((prev) => ({
      ...prev,
      ...errorList,
    }));
  };
  const onChangeAmount = (value?: string) => {
    const number = Number(value);

    setAmount(value);

    handleErrors({
      amount: number > 6000 ? "Максимум 6000" : "",
    });
  };

  const onBlurAmount = () => {
    setFocus(false);
    const len = amount?.length;
    handleErrors({
      amount: validateRequired(!len),
    });
    if (len) {
      calculateCost();
    }
  };

  const onBlurRequired = (key: keyof typeof errors, value: string) => {
    handleErrors({
      [key]: validateRequired(!value.length),
    });
  };

  const clearData = () => {
    setAddress(null);
    setNumber("");
    setAmount("");
    setDetail("");
    setCustomerAddress({
      place_name: "",
      latitude: "",
      longitude: "",
      street: "",
      number: "",
      country: "",
      city: "",
      apartment: "",
      floor: "",
      description: "",
      entrance: "",
      intercom: "",
    });
    setCustomerAddressKey((prev) => prev + 1);
    setSlots(null);
    setSelectedSlot(null);
    setPhone("");
    setName("");
    setEmail("");
    setRecipientPhone("");
    setRecipient("");
  };

  const onClickSlot = () => {
    if (slotsVisible) {
      return setSlotsVisible(false);
    } else {
      setSlotsVisible(true);
    }

    openSlotModal();
  };

  const openSlotModal = () => {
    if (slotLoading) {
      return;
    }
    const addressError = validateRequired(!address);
    const customerAddressError = validateRequired(!customerAddress.street);
    const numberError = validateRequired(!number);
    const amountError = validateRequired(!amount);
    handleErrors({
      customerAddress: customerAddressError,
      address: addressError,
      number: numberError,
      amount: amountError,
    });

    if (addressError || customerAddressError || numberError || amountError) {
      return;
    }

    const requestData = {
      shops: [
        {
          place: placePoint,
          amount: Number(amount),
        },
      ],
      delivery_point: deliveryPoint,
    };

    setSlotLoading(true);
    OrdersApi.getSlots(requestData).then(({ data }) => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setSlots(data);
      setSlotsVisible(true);
      setSlotLoading(false);
    });
  };

  const getSlots = () => {
    const addressError = validateRequired(!address);
    const customerAddressError = validateRequired(!customerAddress.street);
    const numberError = validateRequired(!number);
    const amountError = validateRequired(!amount);
    handleErrors({
      customerAddress: customerAddressError,
      address: addressError,
      number: numberError,
      amount: amountError,
    });

    if (addressError || customerAddressError || numberError || amountError) {
      return;
    }

    const requestData = {
      shops: [
        {
          place: placePoint,
          amount: Number(amount),
        },
      ],
      delivery_point: deliveryPoint,
    };

    setSlotLoading(true);
    OrdersApi.getSlots(requestData).then(({ data }) => {
      setSlots(data);
    });
  };

  const calculateCost = (slot?: SelectSlot) => {
    const currentSlot = slot || selectedSlot;
    if (currentSlot) {
      const requestData: CalculateData = {
        order_type: "order_courier",
        delivery_date: convertDayToDate(currentSlot.day),
        delivery_point: deliveryPoint,
        shops: [
          {
            place: placePoint,
            amount: Number(amount),
            parcel_weight: 0,
          },
        ],
        slot: currentSlot?.slot_number,
      };
      return OrdersApi.calculate(requestData).then(({ data }) => {
        setServiceCost(data.amount_cash);
      });
    }
  };

  const onSelectSlot = (slot: SelectSlot) => {
    setSelectedSlot(slot);

    setSlotsVisible(false);
    if (slot.slot_number !== selectedSlot?.slot_number) {
      if (amount?.length) {
        calculateCost(slot);
      }
      handleErrors({
        amount: validateRequired(!amount?.length),
      });
    }
  };

  const onSelectSlotMobile = (e: string) => {
    const value = e.split(",");
    const slot = {
      slot_number: Number(value[0]),
      day: value[1] as DayLiteral,
      slot_from: "",
      slot_to: "",
    };
    setSelectedSlot(slot);

    if (slot.slot_number !== selectedSlot?.slot_number) {
      if (amount?.length) {
        calculateCost(slot);
      }
      handleErrors({
        amount: validateRequired(!amount?.length),
      });
    }
  };
  const onSelectAddress = (address: Address) => {
    setAddress(address);
    handleErrors({
      address: "",
    });
  };

  const onSelectCustomerAddress = (placeAddress: PlaceAddress) => {
    const location = new google.maps.LatLng(
      Number(placeAddress.latitude),
      Number(placeAddress.longitude)
    );
    if (mkadPolygonCheck(location)) {
      setCustomerAddress((prev) => ({
        ...prev,
        ...placeAddress,
      }));
      handleErrors({
        customerAddress: "",
      });
      setDisabled(false);
      calculateCost();
    } else {
      handleErrors({
        customerAddress: "Тут пока не работаем",
      });
      setDisabled(true);
    }
  };

  const onSetPhones = (value: string) => {
    if (phone === recipientPhone) {
      const length = value.length;
      const firstLetter = length ? value[1] : "";
      if (firstLetter !== RUSSIAN_CODE && firstLetter !== " ") {
        const slicedPhone = value.slice(2);
        let phone = RUSSIAN_CODE + slicedPhone;
        setRecipientPhone(phone);
        setPhone(phone);
      } else {
        setRecipientPhone(value);
        setPhone(value);
      }
    } else {
      setRecipientPhone(value);
    }
  };
  const onChangePhone = (value: string) => {
    const length = value.length;
    const firstLetter = length ? value[1] : "";
    if (firstLetter !== RUSSIAN_CODE && firstLetter !== " ") {
      const slicedPhone = value.slice(2);
      let phone = RUSSIAN_CODE + slicedPhone;
      setPhone(phone);
    } else {
      setPhone(value);
    }
  };

  const onSetNames = (value: string) => {
    if (recipient === name) {
      setRecipient(value);
      setName(value);
    } else {
      setRecipient(value);
    }
  };

  const changeCustomerAddress = (name: AddressLiteral, value: string) => {
    setCustomerAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const changeOperator = () => {
    setPhone(formattedNumber ? formattedNumber : "");
    setEmail(userStore.user?.email ? userStore.user?.email : "");
    setName(userStore.user?.first_name ? userStore.user?.first_name : "");
    setDisablePayment(false);
  };

  const onCreate = () => {
    const addressError = validateRequired(!address);
    const customerAddressError = validateRequired(!customerAddress.street);
    const numberError = validateRequired(!number);
    const phoneError = validateRequired(clearlyPhone.length !== PHONE_LENGTH);

    handleErrors({
      address: addressError,
      customerAddress: customerAddressError,
      number: numberError,
      phone: phoneError,
    });

    if (addressError || customerAddressError || numberError || phoneError) {
      return;
    }
    setDisabled(true);
    OrdersApi.shopOrderCreate({
      number_in_shop: number,
      order_details: detail,
      shop_address: address?.id || 0,
      amount: Number(amount),
      deliveryorder: {
        time_from: selectedSlot?.slot_number || 0,
        delivery_date: deliveryDate,
        ...customerAddress,
        ...deliveryPoint,
        contact: {
          name: recipient ? recipient : "",
          phone: `+${clearlyRecipientPhone}`,
        },
      },
      customer_phone: `+${clearlyPhone}`,
      ...(email ? { customer_email: email } : {}),
      ...(name ? { customer_name: name } : {}),
      require_termobag: thermobag,
    })
      .then(() => {
        modalStore.openModal({
          title: "Заказ отправлен Заказчику",
          messages: [
            "📨 Отправили Заказчику СМС со ссылкой на оплату. Ссылка действует 15 минут.",
          ],
          icon: "success",
          button: {
            title: "В заказы",
            onClick: () => history.push(RouterNames.Orders),
          },
        });
      })
      .finally(() => {
        setDisabled(true);
        setDisabledRecipient(true);
        clearData();
      });
  };

  const onCreateRecipient = () => {
    const addressError = validateRequired(!address);
    const customerAddressError = validateRequired(!customerAddress.street);
    const numberError = validateRequired(!number);
    const recipientPhoneError = validateRequired(
      clearlyRecipientPhone.length !== PHONE_LENGTH
    );

    handleErrors({
      address: addressError,
      customerAddress: customerAddressError,
      number: numberError,
      recipientPhone: recipientPhoneError,
    });

    if (
      addressError ||
      customerAddressError ||
      numberError ||
      recipientPhoneError
    ) {
      return;
    }
    setDisabledRecipient(true);
    OrdersApi.shopRecipientOrderCreate({
      number_in_shop: number,
      order_details: detail,
      shop_address: address?.id || 0,
      amount: Number(amount),
      deliveryorder: {
        time_from: selectedSlot?.slot_number || 0,
        delivery_date: deliveryDate,
        ...customerAddress,
        ...deliveryPoint,
        contact: {
          name: recipient ? recipient : "",
          phone: `+${clearlyRecipientPhone}`,
        },
      },
      customer_phone: `+${clearlyPhone}`,
      ...(email ? { customer_email: email } : {}),
      ...(name ? { customer_name: name } : {}),
      require_termobag: thermobag,
    })
      .then((res) => {
        window.open(res.data.url, "_blank");
        modalStore.openModal({
          title: "Ссылка успешно создана",
          messages: [
            `Если ссылка не открылась в новом окне, то нажмите здесь <a href=${res.data.url} target="_blank">${res.data.url}</a> . Действует 15 минут`,
          ],
          icon: "success",
          button: {
            title: "В заказы",
            onClick: () => history.push(RouterNames.Orders),
          },
        });
      })
      .finally(() => {
        setDisabledRecipient(true);
        setDisabled(true);
        clearData();
      });
  };

  const slotDay =
    DAY_LIST.find((item) => item.day === selectedSlot?.day)?.title || "";
  const slotText = selectedSlot
    ? `${selectedSlot.slot_from} - ${selectedSlot.slot_to} ${slotDay}`
    : "";

  const addressList: Autocomplete[] = userStore.addresses.map((address) => ({
    top: address ? `${address.street} ${address.number}` : "",
    bottom: "",
    id: address,
  }));

  React.useEffect(() => {
    if (customerAddress.street.length) {
      handleErrors({
        customerAddress: "",
      });
      getSlots();
    }
  }, [customerAddress.street]);

  React.useEffect(() => {
    if (!userStore.addresses.length) {
      history.push(RouterNames.Home);
    }
  }, [history, userStore.addresses.length]);

  React.useEffect(() => {
    if (phone === formattedNumber) {
      setDisablePayment(false);
    }

    if (phone !== formattedNumber) {
      setDisablePayment(true);
    }
  }, [phone]);
  React.useEffect(() => {
    if (
      address &&
      amount &&
      customerAddress &&
      phone != "+7 (9  )    -  -  " &&
      `+${clearlyPhone}` === userStore.user?.phone &&
      clearlyPhone.length === 11
    ) {
      setDisabledRecipient(false);
    } else {
      setDisabledRecipient(true);
    }
  }, [address, amount, customerAddress, phone]);

  return (
    <div className="create page">
      <div className="create--title">{CreateData.FirstColumn.title}</div>
      <div className="create--description">
        {CreateData.FirstColumn.description}
      </div>
      <div className="create--row">
        <div className="create--row__item">
          <TextField
            autocompleteList={addressList}
            autoComplete="chrome-off"
            value={
              address
                ? `${address.place_name} | ${address.street}, ${address.number}`
                : ""
            }
            onChangeText={() => setAddress(null)}
            onSelectItem={onSelectAddress}
            placeholder={CreateData.FirstColumn.inputs.shop.placeholder}
            description={CreateData.FirstColumn.inputs.shop.description}
            error={errors.address}
            testId="shop_address"
          />
        </div>
        <div className="create--row__item">
          <TextField
            error={errors.number}
            value={number}
            onChangeText={setNumber}
            onBlur={() => onBlurRequired("number", number)}
            placeholder={CreateData.FirstColumn.inputs.number.placeholder}
            description={CreateData.FirstColumn.inputs.number.description}
            testId="number_in_system"
          />
        </div>
      </div>
      <div className="create--row mb-5">
        <div className="create--row__item">
          <TextField
            error={errors.amount}
            customInput={
              <CurrencyInput
                decimalSeparator="."
                groupSeparator=""
                disableGroupSeparators
                maxLength={8}
                inputMode="decimal"
                value={amount}
                onValueChange={onChangeAmount}
                onFocus={() => setFocus(true)}
                decimalsLimit={2}
                max={6000}
                onBlur={() => onBlurAmount()}
              />
            }
            testId="order_sum"
            customFocus={focus}
            value={amount}
            placeholder={CreateData.FirstColumn.inputs.amount.placeholder}
            description={CreateData.FirstColumn.inputs.amount.description}
          />
        </div>
        <div className="create--row__item lightgray">
          <TextField
            value={detail}
            onChangeText={setDetail}
            placeholder={CreateData.FirstColumn.inputs.details.placeholder}
            description={CreateData.FirstColumn.inputs.details.description}
            testId="order_details"
          />
        </div>
      </div>
      <div className="create--title">{CreateData.SecondColumn.title}</div>
      <div className="create--description">
        {CreateData.SecondColumn.description}
      </div>
      <div className="create--row">
        <div className="create--row__item">
          <TextFieldPlaces
            key={customerAddressKey}
            autoComplete="chrome-off"
            onSelect={onSelectCustomerAddress}
            placeholder={CreateData.SecondColumn.inputs.address.placeholder}
            error={errors.customerAddress}
            testId="order_street"
          />
        </div>
        <div className="create--row__item">
          <div className="create--row row">
            <div className="row">
              <div className="create--row__item mr-9 lightgray">
                <TextField
                  value={customerAddress?.apartment}
                  onChangeText={(text) =>
                    changeCustomerAddress("apartment", text)
                  }
                  placeholder={
                    CreateData.SecondColumn.inputs.apartment.placeholder
                  }
                  testId="apartment_number"
                />
              </div>
              <div className="create--row__item mr-9 lightgray">
                <TextField
                  value={customerAddress.entrance}
                  onChangeText={(text) =>
                    changeCustomerAddress("entrance", text)
                  }
                  placeholder={
                    CreateData.SecondColumn.inputs.entrance.placeholder
                  }
                  testId="entrance_number"
                />
              </div>
            </div>
            <div className="row">
              <div className="create--row__item lightgray">
                <TextField
                  value={customerAddress.intercom}
                  onChangeText={(text) =>
                    changeCustomerAddress("intercom", text)
                  }
                  placeholder={
                    CreateData.SecondColumn.inputs.intercom.placeholder
                  }
                  testId="intercom_number"
                />
              </div>
              <div className="create--row__item lightgray">
                <TextField
                  value={customerAddress.floor}
                  onChangeText={(text) => changeCustomerAddress("floor", text)}
                  placeholder={CreateData.SecondColumn.inputs.floor.placeholder}
                  testId="foor_number"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="create--row reverse mb-5">
        <div className="create--row__item hidden-mobile position-relative">
          {slotsVisible && slots ? (
            <SlotsModal
              slots={slots}
              close={() => setSlotsVisible(false)}
              selectedSlot={selectedSlot}
              onSelectSlot={onSelectSlot}
            />
          ) : null}
          <ButtonSelect
            onClick={onClickSlot}
            placeholder={CreateData.SecondColumn.inputs.time.placeholder}
            description={CreateData.SecondColumn.inputs.time.description}
            value={slotText}
          />
        </div>
        <div className="create--row__item hidden-desktop position-relative">
          <MobileSlots
            onSelectSlotMobile={onSelectSlotMobile}
            getSlots={getSlots}
            selectedSlot={selectedSlot}
            slots={slots}
          />
        </div>
        <div className="create--row__item lightgray">
          <TextField
            value={customerAddress.description}
            onChangeText={(text) => changeCustomerAddress("description", text)}
            placeholder={CreateData.SecondColumn.inputs.comment.placeholder}
            testId="description"
          />
        </div>
      </div>

      <div className="create--row">
        <div className="create--row__item">
          <TextField
            error={errors.recipientPhone}
            value={recipientPhone}
            type="tel"
            onChangeText={(value) => {
              onSetPhones(value);
            }}
            onBlur={() =>
              handleErrors({
                recipientPhone: validateRequired(
                  clearlyRecipientPhone.length !== PHONE_LENGTH
                ),
              })
            }
            mask="+# (###) ###-##-##"
            placeholder={CreateData.FourthColumn.inputs.phone.placeholder}
            testId="receiver_phone"
          />
        </div>
        <div className="create--row__item">
          <TextField
            value={recipient}
            onChangeText={(value) => {
              onSetNames(value);
            }}
            placeholder={CreateData.FourthColumn.inputs.name.placeholder}
            testId="receiver_name"
          />
        </div>
        <div className="create--row__item">
          <div className="create--row__item--termo">
            <div className="create--row__item--desc">
              <div className="create--row__item--desc--title medium-16">
                {CreateData.FourthColumn.button.termobags.title}
              </div>
              <div className="create--row__item--desc--text medium-12">
                {CreateData.FourthColumn.button.termobags.description}
              </div>
            </div>
            <div
              className={`create--row__item--btn${thermobag ? "--active" : ""}`}
            >
              <AppButtonCenter
                onClick={() => setThermobag(true)}
                disabled={disabled}
                title={"Да"}
              />

              <AppButtonCenter
                onClick={() => setThermobag(false)}
                title={"Нет"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="create--title">{CreateData.ThirdColumn.title}</div>
      <div className="create--description">
        {CreateData.ThirdColumn.description}
      </div>
      <div className="create--row reverse">
        <div className="create--row__item">
          <TextField
            error={errors.phone}
            value={phone}
            type="tel"
            onChangeText={onChangePhone}
            onBlur={() =>
              handleErrors({
                phone: validateRequired(clearlyPhone.length !== PHONE_LENGTH),
              })
            }
            mask="+# (###) ###-##-##"
            placeholder={CreateData.ThirdColumn.inputs.phone.placeholder}
            testId="customer_phone"
          />
        </div>
        <div className="create--row__item">
          <TextField
            value={email}
            onChangeText={setEmail}
            placeholder={CreateData.ThirdColumn.inputs.email.placeholder}
            testId="customer_email"
          />
        </div>
        <div className="create--row__item">
          <TextField
            value={name}
            onChangeText={setName}
            placeholder={CreateData.ThirdColumn.inputs.name.placeholder}
            testId="customer_name"
          />
        </div>
        <div className="create--row__item">
          <div
            className={`create--row__item--payment${
              disablePayment ? "" : "--active"
            }`}
          >
            <div className="create--row__item">
              <div className="create--row__item--payment--title medium-16">
                {CreateData.FourthColumn.button.payment.title}
              </div>
              <div className="create--row__item--payment--text medium-12">
                {CreateData.FourthColumn.button.payment.description}
              </div>
            </div>
            <AppButtonCenter onClick={changeOperator} title={"Да"} />
          </div>
        </div>
      </div>
      <div className="create--row__btn">
        <AppButton
          onClick={onCreate}
          disabled={disabled || !serviceCost}
          leftText="Отправить СМС на оплату Заказчику"
          rightText={
            number &&
            address &&
            customerAddress &&
            phone && (
              <div className="create--amount">
                <div className="bold-20">
                  Услуга {serviceCost ? serviceCost.toFixed(2) : "-"}{" "}
                  <span className="rouble">₽</span>
                </div>
                <div className="bold-12">
                  + заказ {amount ? Number(amount).toFixed(2) : "-"}{" "}
                  <span className="rouble">₽</span>
                </div>
              </div>
            )
          }
        />
        <AppButton
          onClick={onCreateRecipient}
          disabled={disabledRecipient || !serviceCost}
          leftText={CreateData.FifthColumn.text.nonempty.title}
          leftBottomText={CreateData.FifthColumn.text.nonempty.description}
          rightText={
            !disabledRecipient && (
              <div className="create--amount">
                <div className="bold-20">
                  Услуга {serviceCost ? serviceCost.toFixed(2) : "-"}{" "}
                  <span className="rouble">₽</span>
                </div>
                <div className="bold-12">
                  + заказ {amount ? Number(amount).toFixed(2) : "-"}{" "}
                  <span className="rouble">₽</span>
                </div>
              </div>
            )
          }
        />
      </div>
    </div>
  );
};
