import * as React from "react";
import { SlotResponse } from "src/services/api/OrdersApi";
import { DAY_LIST } from "./SlotsModal";
import { CreateData } from "../../Create.data";
import "./style.scss";
import { SelectSlot } from "src/types/slots";

interface Props {
    getSlots?(): void;
    slots: SlotResponse | null;
    selectedSlot?: SelectSlot | null;
    onSelectSlotMobile: (e: string) => void;
}

export const MobileSlots: React.FC<Props> = ({
    getSlots,
    onSelectSlotMobile,
    selectedSlot,
    slots,
}) => {

    return (
        <>
            <div className={`mobile-slots--placeholder ${selectedSlot ? "active" : ''}`}>{CreateData.SecondColumn.inputs.time.placeholder}</div>
            <select
                onClick={getSlots}
                className="mobile-slots"
                onChange={(value) => onSelectSlotMobile(value.target.value)}
            >
                <option></option>
                {slots?.today.map((today) => {
                    return (
                        <option
                            value={`${today.customer_timeslot.slot_number},${DAY_LIST[0].day}`}
                        >
                            {today.customer_timeslot.slot_from} - {today.customer_timeslot.slot_to} сегодня
                        </option>
                    )
                })}
                {slots?.tomorrow.map((tomorrow) => {
                    return (
                        <option
                            value={`${tomorrow.customer_timeslot.slot_number},${DAY_LIST[1].day}`}
                        >
                            {tomorrow.customer_timeslot.slot_from} - {tomorrow.customer_timeslot.slot_to} завтра
                        </option>
                    )
                })}
                {slots?.after_tomorrow.map((after_tomorrow) => {
                    return (
                        <option
                            value={`${after_tomorrow.customer_timeslot.slot_number},${DAY_LIST[2].day}`}
                        >
                            {after_tomorrow.customer_timeslot.slot_from} - {after_tomorrow.customer_timeslot.slot_to} послезавтра
                        </option>
                    )
                })}
            </select>
        </>
    );
};
